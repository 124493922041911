<template>
  <div class="card filter-card">
    <header class="card-header">
      <div class="card-header-title">Filter</div>
      <button
        class="delete is-small mt-3 mr-3"
        aria-label="close"
        @click="close"
      >
        Lukk
      </button>
    </header>
    <div class="card-content">
      <div class="control">
        <div class="select is-fullwidth">
          <select
            v-model="selectedValue"
            :value="value"
            @input="input"
            @change="useFilter()"
          >
            <option :value="null">-- Velg --</option>
            <option
              v-for="item in options"
              v-bind:value="item.id"
              v-bind:key="item.id"
              :selected="value != null && value == item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FilterModal",
    props: {
      options: {
        type: Array,
        default() {
          return [];
        }
      },
      value: {
        type: [String, Number],
        required: false
      },
      filterName: {
        type: [String],
        required: true
      }
    },
    data: function() {
      return {
        selectedValue: null
      };
    },
    methods: {
      input(event) {
        let value = event.target.value;
        this.selectedValue = value ? value : null;
      },
      useFilter() {
        this.$emit("input", this.filterName, this.selectedValue);
      },
      close() {
        this.$emit("close");
      },
      clearSelected() {
        this.selectedValue = null;
      }
    }
  };
</script>

<style scoped></style>
