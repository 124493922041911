<template>
    <div class="columns">
        <div class="column is-3">
            <div class="control">
                <!-- <ValidationProvider v-slot="{ errors }">
                    <field-select ref="fylkeSelect" label-text="Fylke" v-model.lazy="model.fylkeId" v-bind:options="fylker"
                        :error="errors[0]" />
                </ValidationProvider> -->
            </div>
            <label class="label">Fylke </label>
            <div class="select is-fullwidth">
                <select v-model.lazy="model.fylkeId">
                    <option value=''>-- Velg --</option>
                    <option v-for="fylke in fylker" :key="fylke.id" :value="fylke.id" >{{ fylke.name }}</option>
                </select>
            </div>
        </div>
        <div class="column is-5">
            <label class="label">Vassdrag *</label>
            <div class="control">
                <ValidationProvider :rules="validationRules" v-slot="{ errors }">
                    <v-select class="style-chooser" v-model="model.vassdragId" :reduce="item => item.value"
                        placeholder="Velg vassdrag eller skriv for å søke" v-bind:options="vassdragOptions"
                        @search="fetchOptions">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Ingen resultat for <em>{{ search }}</em>.
                            </template>
                            <em v-else style="opacity: 0.5">Ingen vassdrag funnet for valgt fylke</em>
                        </template>
                    </v-select>
                    <p class="help is-danger">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
        </div>


        <div class="column is-1">
            <label class="label">Fylkenr</label>
            <div class="control">
                {{ model.fylkeId }}
            </div>
        </div>
        <div class="column is-1">
            <label class="label">Lregnr</label>
            <div class="control">
                {{ model.vassdragId }}
            </div>
        </div>
        <div class="column is-3" v-show="angiProvested">
            <field-input v-model="model.funnsted" :disable="textfieldNotEditable"
                label-text="Prøvested uten lregnr"></field-input>
        </div>


    </div>
</template>

<script>
import api from "@/api/basicApi.js";
import fieldInput from '../globals/field-input.vue';
export default {
    components: { fieldInput },
    props: {
        model: {
            type: Object,
            required: true,
        },
        clearSelect: {
            type: Boolean,
            required: false

        },
        validationRules: {
            type: Object,
            required: false
        },
        angiProvested: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: function () {
        return {
            fylker: [],
            vassdrag: []
        }
    },

    mounted() {
        var self = this;
        api.fetchData({ url: "Fiskebase/GetFylker" }).then(res => {
            self.fylker = res.data;
        });


    },
    watch: {
        'model.fylkeId'(value) {
            this.getVassdrag(value);
        },
        clearSelect(value) {
            if (value) {
                this.$refs.fylkeSelect.clearSelection();
            }
        }
    },
    computed: {
        vassdragOptions() {
            return this.vassdrag.map(item => ({ value: item.id, label: item.name }));
        },
        textfieldNotEditable() {
            return this.model.vassdragId == null || this.model.vassdragId > 0
        }
    },
    methods: {
        getVassdrag(newFylkeId) {
            var self = this;

            if (newFylkeId != '') {
                api.fetchData({ url: "Fiskebase/GetVassdragForFylke/" + newFylkeId }).then(res => {
                    self.vassdrag = res.data;
                    self.vassdrag.push({ id: 0, name: 'Uten Lregnr' })
                })
            } else {
                self.vassdrag = [];
            }
        },
        fetchOptions(search, loading) {
            if (search.length >= 3 && this.model.fylkeId == "") {
                loading(true);
                var self = this;
                api.fetchData({ url: "Fiskebase/GetVassdragForNavn/" + search }).then(res => {
                    self.vassdrag = res.data;
                    self.vassdrag.push({ id: 0, name: 'Uten Lregnr' })
                    loading(false);
                })

            }
        }

    }
}

</script>