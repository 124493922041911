<template>
  <div class="home">

    <section class="section">

      <h1 class="title">Hei {{ userName }}</h1>
      <h2 class="subtitle">
        Velkommen til Biobase, NINAs biologiske prøvebase
      </h2>

      <p>Her kan du søke frem og registrere biologiske prøver. Funksjonene tilgjengelig er basert på dine roller og tilganger.</p>

    </section>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data: function () {
    return {
      user: JSON.parse(localStorage.getItem("user"))
    };
  },
  computed: {
    userName() {
      return this.user.firstName;
    }
  }
}
</script>
