<template>
    <div>
        <section class="section">
            <div class="container">
                <div class="tabs is-boxed">
                    <ul>
                        <li v-show="fiskeskjellRolle" v-bind:class="{ 'is-active': currentRoute == 'registrer' }" @click="changeRoute('registrer')">
                            <a>
                                <span class="icon is-small"><i class="fas fa-edit" aria-hidden="true"></i></span>
                                <span>Registrer ny</span>
                            </a>
                        </li>
                        <li v-bind:class="{ 'is-active': includePath('aktivitet') }" @click="changeRoute('aktivitet')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-tasks" aria-hidden="true"></i></span>
                                <span>Registrer aktivitet</span>
                            </a>
                        </li>
                        <li v-bind:class="{ 'is-active': currentRoute == 'list' }" @click="changeRoute('list')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-chart-bar" aria-hidden="true"></i></span>
                                <span>Vassdragsoversikt</span>
                            </a>
                        </li>
                        <li v-show="fiskeskjellRolle" v-bind:class="{ 'is-active': currentRoute == 'fisketider' }" @click="changeRoute('fisketider')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                <span>Fisketider</span>
                            </a>
                        </li>
                        <li v-show="fiskeskjellRolle" v-bind:class="{ 'is-active': currentRoute == 'prosjekt' }" @click="changeRoute('prosjekt')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-cog" aria-hidden="true"></i></span>
                                <span>Prosjekt</span>
                            </a>
                        </li>
                        <li v-show="fiskeskjellRolle" v-bind:class="{ 'is-active': currentRoute == 'betaling' }" @click="changeRoute('betaling')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-coins" aria-hidden="true"></i></span>
                                <span>Betalingsoversikt</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <div v-if="currentRoute == 'registrer' && fiskeskjellRolle">
           <registrering></registrering>
        </div>

        <div v-if="currentRoute == 'list'" >
           <fiskeskjellListe></fiskeskjellListe>
        </div>

        <div v-if="includePath('aktivitet') ">
           <fiskeskjellAktivitet></fiskeskjellAktivitet>
        </div>

        <div v-if="includePath('fisketider') && fiskeskjellRolle">
           <fisketider></fisketider>
        </div>

        <div v-if="currentRoute == 'betaling' && fiskeskjellRolle">
           <betaling></betaling>
        </div>

        <div v-if="currentRoute == 'prosjekt' && fiskeskjellRolle">
           <prosjekt :globalTypeId="5"></prosjekt>
        </div>


    </div>
</template>
<script>

import MottakFiskeskjellRegistrering from '../components/mottakFiskeskjell/MottakFiskeskjellRegistrer.vue'
import MottakFiskjeskjellListe from '../components/mottakFiskeskjell/MottakFiskeskjellListe.vue'
import MottakFiskeskjellAktivitet from '../components/mottakFiskeskjell/MottakFiskeskjellAktivitet.vue'
import MottakFiskeskjellFisketider from '../components/mottakFiskeskjell/MottakFiskeskjellFisketider.vue'
import MottakFiskeskjellBetaling from '../components/mottakFiskeskjell/betaling/MottakFiskeskjellBetaling.vue'
import Prosjekt from '../components/grouped/Prosjekt.vue'

export default {
    components: { 
        "registrering": MottakFiskeskjellRegistrering, 
        "fiskeskjellListe": MottakFiskjeskjellListe, 
        "fiskeskjellAktivitet": MottakFiskeskjellAktivitet, 
        'fisketider': MottakFiskeskjellFisketider,
        'prosjekt': Prosjekt,
        'betaling': MottakFiskeskjellBetaling 
    },
    data: function () {
        return {
            tabselect: 'register',
            user: JSON.parse(localStorage.getItem("user"))
        }
    },
    mounted() {
        if(this.fiskeskjellRolle) {
            this.$router.push({name: "registrer"})
        } else {
            this.$router.push({name: "aktivitet"})
        }
    },
    methods: {
        changeRoute(routeName) {
            this.$router.push({name: routeName})
        },
        includePath(urlPart) {
            return this.$route.path.includes(urlPart)
        }
    },  
    computed: {
        currentRoute() {
            return this.$route.name;
        },
        fiskeskjellRolle() {
            return this.user != null && (this.user.accountRoles.includes(1) || this.user.accountRoles.includes(5));
        }
        
    }
}


</script>