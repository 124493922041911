import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Individual from "../views/Individual.vue";
import Login from "../components/user/Login.vue";
import RegisterUser from "../components/user/RegisterUser.vue";
import VerifyEmail from "../components/user/VerifyEmail.vue";
import ForgotPassword from "../components/user/ForgotPassword.vue";
import ResetPassword from "../components/user/ResetPassword.vue";
import MottaksFryser from "../views/MottaksFryser.vue";
import MottakFiskeskjell from "../views/MottakFiskeskjell.vue";
import MottakFiskeskjellRegistrer from "../components/mottakFiskeskjell/MottakFiskeskjellRegistrer.vue"
import MottakFiskeskjellListe from "../components/mottakFiskeskjell/MottakFiskeskjellListe.vue"
import MottakFiskeskjellAktivitet from "../components/mottakFiskeskjell/MottakFiskeskjellAktivitet.vue"
import MottakFiskeskjellFisketider from "../components/mottakFiskeskjell/MottakFiskeskjellFisketider.vue"
import MottakFiskeskjellBetaling from "../components/mottakFiskeskjell/betaling/MottakFiskeskjellBetaling.vue"
import Opplegg from "../components/mottakFiskeskjell/aktivitet/Opplegg.vue"
import Analyse from "../components/mottakFiskeskjell/aktivitet/Analyse.vue"
import BulkEdit from "../components/mottaksfryser/BulkEdit.vue"
import Prosjekt from "../components/grouped/Prosjekt.vue" 
import FindOrCreateIndividual from "../views/FindOrCreateIndividual.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    //redirect: { name: "Search" },
    name: "Home",
    component: Home
  },
  {
    path: "/search",
    name: "Search",
    component: Search
  },
  {
    path: "/mottak/:guid?",
    name: "Mottak",
    component: MottaksFryser,
     children: [
      {
        path: 'edit',
        name: 'edit',
        component: BulkEdit
      }
    
     ]
  },
  {
    path: "/mottakFiskeskjell",
    name: "MottakFiskeskjell",
    component: MottakFiskeskjell,
    children: [
      {
        path: 'registrer/:id?',
        name: 'registrer',
        component: MottakFiskeskjellRegistrer
      },
      {
        path: 'list',
        name: 'list',
        component: MottakFiskeskjellListe
      },
      {
        path: 'betaling',
        name: 'betaling',
        component: MottakFiskeskjellBetaling
      },
      {
        path: 'fisketider',
        name: 'fisketider',
        component: MottakFiskeskjellFisketider
      },
      {
        path: 'prosjekt',
        name: 'prosjekt',
        component: Prosjekt
      },
      {
        path: 'aktivitet',
        name: 'aktivitet',
        component: MottakFiskeskjellAktivitet,
        children: [
          {
            path: 'opplegg/:vassdragId/:funnsted?/:fylkeId?',
            name: 'opplegg',
            component: Opplegg

          },
          {
            path: 'analyse/:vassdragId/:funnsted?/:fylkeId?',
            name: 'analyse',
            component: Analyse

          }
        ]
      },

    ]
  },
   {
     path: "/individual",
     name: "FindOrCreateIndividual",
     component: FindOrCreateIndividual
   }, 
  {
    path: "/individual/:individualId",
    name: "Individual",
    component: Individual
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  { path: "/login", component: Login, name: "Login" },
  { path: "/registerUser", component: RegisterUser },
  { path: "/verifyEmail/", component: VerifyEmail },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/resetPassword", component: ResetPassword }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/login",
    "/registerUser",
    "/forgotPassword",
    "/verifyEmail",
    "/resetPassword",
  ];
  const authRequired = !publicPages.includes(to.path);
  const isLoggedIn = localStorage.getItem("user");
  
  
  if (authRequired && !isLoggedIn) {
    return router.push({ path: "/login" });
  }
  const user = JSON.parse(localStorage.getItem("user"));
  if (to.path == "/register/new" || to.name == "Register") {
    
    if (user.accountRoles.includes(1) || user.accountRoles.includes(2)){
      return next();
    } else {
      alert('Du har ikke lov til å registrere hjortevilt')
      return router.push({ path: from.path });
    }

  }

  next();

});
export default router;
