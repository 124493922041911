<template>
    <div class="container">
        <section class="section">
            <h1 class="is-size-2">Prosjekter</h1>
            <h3 class="is-size-4 mb-5">På denne siden kan du administrere dine prosjekter</h3>

            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Prosjektnummer</th>
                        <th>Navn</th>
                        <th>Aktiv</th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="prosjekt in prosjekter" :key="prosjekt.id">
                        <td> <span>{{ prosjekt.name }}</span></td>
                        <td>{{ prosjekt.description }}</td>
                        <td>
                            <span :class="isActive(prosjekt.active)">
                                <i class="fas fa-check"></i>
                            </span>
                        </td>
                        <td> <a @click="editProsjekt(prosjekt.id)">Endre</a> </td>


                    </tr>
                </tbody>
            </table>

            <button type="button" class="button is-info" @click="opprettNyttProsjekt">
                <span class="icon is-small">
                    <i class="fa fa-plus"></i>
                </span>
                <span>Nytt prosjekt</span>
            </button>

        </section>

        <section class="section" v-show="registrerNy">
            <h3 class="is-size-4 mb-5">Prosjekt</h3>
            <ValidationObserver ref="form">
                <div class="field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="label">Prosjektnummer</label>
                        <field-input v-model="nyttProsjekt.name"></field-input>
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>

                </div>

                <div class="field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="label">Navn</label>
                        <field-input v-model="nyttProsjekt.description"></field-input>
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>

                </div>

                <div class="field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="label">Aktiv</label>

                        <label class="radio">
                            <input type="radio" name="answer" v-model="nyttProsjekt.active" :value=true>
                            Ja
                        </label>
                        <label class="radio">
                            <input type="radio" name="answer" v-model="nyttProsjekt.active" :value=false>
                            Nei
                        </label>

                        <p class="help">Prosjekt som er aktive vises i nedtrekkslisten ved registrering av innkomne prøver
                        </p>
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>

                <div class="field is-grouped mt-6">
                    <div class="control">
                        <button class="button is-info" @click="registrerNyttProsjekt()">
                            <span class="icon is-small">
                                <i class="fa fa-save"></i>
                            </span>
                            <span>Registrer</span></button>
                    </div>
                    <div class="control">
                        <button class="button is-default" @click="registrerNy = false">
                            <span class="icon is-small">
                                <i class="fa fa-times"></i>
                            </span>
                            <span>Avbryt</span>
                        </button>
                    </div>
                    <div class="control" v-if="nyttProsjekt.id > 0">
                        <button class="button is-danger is-outlined" @click="slettProsjekt()">
                            <span class="icon is-small">
                                <i class="fa fa-trash"></i>
                            </span>
                            <span>Slett</span>
                        </button>
                    </div>

                </div>
            </ValidationObserver>
        </section>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

    </div>
</template>
  
<script>
import api from "@/api/basicApi.js";
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
import FieldInput from '../globals/field-input.vue';


export default {

    data: function () {
        return {
            prosjekter: [],
            registrerNy: false,
            nyttProsjekt: {}
        }
    },
    props: {
        globalTypeId: {
            type: Number,
            required: true
        },
    },
    components: {
        // FieldSelect,
        "confirm-dialogue": ConfirmDialogue,
        FieldInput
    },
    mounted() {
        let self = this;
        api.fetchData({ url: "Prosjekt/GetProsjekter/" + self.globalTypeId })
            .then(res => {
                self.prosjekter = res.data

            });


    },
    computed: {


    },
    methods: {
        isActive(active) {
            if (active) return 'has-text-success'
            else return 'has-text-grey-lighter'
        },
        registrerNyttProsjekt() {
            let self = this;
            self.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else {
                    api.saveData({ url: "Prosjekt/SaveProsjekt/", data: self.nyttProsjekt })
                        .then(res => {
                            self.registrerNy = false;
                            const index = self.prosjekter.findIndex(p => p.id === res.data.id)
                            if (index === -1) {
                                self.prosjekter.push(res.data)
                            } else {
                                self.prosjekter[index] = res.data
                            }
                        })
                }
            })
        },
        editProsjekt(id) {
            var prosjektToEdit = this.prosjekter.find(a => a.id === id);
            const copiedProsjekt = { ...prosjektToEdit };
            this.nyttProsjekt = copiedProsjekt;
            this.registrerNy = true;
        },
        opprettNyttProsjekt() {
            this.nyttProsjekt = {
                globalTypeId: this.globalTypeId
            }
            this.registrerNy = true;
        },

        slettProsjekt() {
            let self = this
            this.$refs.confirmDialogue.show({
                title: 'Slett prosjekt',
                message: 'Er du sikker på at du vil slette prosjektet? Dette er ikke mulig hvis prosjektet er knyttet til registreringer. Prosjektet kan i stedet settes inaktivt',
                okButton: 'Slett',
            }).then((result) => {
                if (result) {
                    api.deleteData({ url: "Prosjekt/SlettProsjekt/" + self.nyttProsjekt.id }).then(res => {
                        if (res) {
                            self.registrerNy = false;
                            const index = self.prosjekter.findIndex(a => a.id === self.prosjekter.id)
                            self.prosjekter.splice(index, 1)
                        }
                    })
                }
            })
        }

    }
};
</script>
<style scoped>
.table td {
    vertical-align: middle;
}

.center {
    margin-top: 35px;
}
</style>
  