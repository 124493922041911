<template>
    <div>
        <section class="section">
            <div class="container">
                <div class="tabs is-boxed">
                    <ul>
                        <li v-bind:class="{ 'is-active': currentRoute == 'Mottak' }" @click="changeRoute('Mottak')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-qrcode" aria-hidden="true"></i></span>
                                <span>Mottak inn eller ut</span>
                            </a>
                        </li>
                        <li v-bind:class="{ 'is-active': currentRoute == 'edit' }" @click="changeRoute('edit')">
                            <a>
                                <span class="icon is-small"><i class="fa fa-list" aria-hidden="true"></i></span>
                                <span>Oversikt</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div v-if="currentRoute == 'Mottak'">

                    <div class="column is-6">
                        <div class="box has-background-info-light">
                            <div v-if="useCamera" class="mb-2">
                                <qrcode-stream @decode="onDecode" :track="paintBoundingBox" @init="onInit"></qrcode-stream>
                                <p class="error">{{ error }}</p>
                            </div>
                            <div class="field has-addons">
                                <div class="control is-expanded">
                                    <input class="input" type="text" placeholder="Skann QR-kode" v-model="guid" ref="qrCodeReader"
                                        @keyup="searchByGuid()">

                                </div>

                                <div class="control">
                                    <a class="button is-default">
                                        <button class="delete is-small" @click=" clearGuid()"></button>
                                    </a>
                                </div>

                                <div class="control">
                                    <a class="button is-info" @click="useCamera = !useCamera">
                                        <span class="icon is-small">
                                            <i class="fas fa-camera"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <span class="help has-text-danger"> {{ guidError }}</span>
                        </div>
                    </div>
                    <div v-if="søk">
                        <mottak-qr :guid="guid" @clear="cancel"></mottak-qr>
                    </div>
                </div>
                <div v-if="currentRoute == 'edit'">
                    <edit @showUnitByGuid="showUnitByGuid"></edit>
                </div>

            </div>
        </section>
    </div>
</template>
  
<script>

import MottakQr from "@/components/mottaksfryser/MottakQr.vue"
import BulkEdit from "@/components/mottaksfryser/BulkEdit.vue"
import { QrcodeStream } from 'vue-qrcode-reader'

export default {

    data: function () {
        return {
            guid: null,
            guidError: '',
            tabselect: 'qr',
            prøve: null,
            søk: false,
            useCamera: false,
            error: ''
        }
    },
    components: {
        "mottak-qr": MottakQr,
        "edit": BulkEdit,
        "qrcode-stream": QrcodeStream
    },
    mounted() {
        if (this.$route.params.guid) {
            this.showUnitByGuid(this.$route.params.guid)
        }
        if (this.$route.name == "Mottak"){
            this.focusInput();
        }
        
    },
    computed: {
        currentRoute() {
            return this.$route.name;
        }
    },
    methods: {
        changeRoute(routeName) {
            this.$router.push({ name: routeName })
        },
        clearGuid() {
            this.guid = null
            this.guidError = ''
            this.søk = false
        },
        showUnitByGuid(qr) {
            this.guid = qr;
            this.searchByGuid();
            this.tabselect = 'qr';
        },
        searchByGuid() {
            let self = this;
            self.guidError = ''
            if (self.guid == '') {
                self.søk = false;
            }

            else if (self.guid.length != 36) {
                self.søk = false;
                self.guidError = "Ugyldig qrkode"
            }
            else {
                self.søk = true
            }
        },
        onDecode(decodedString) {
            this.guid = decodedString
            this.searchByGuid(decodedString)
            this.useCamera = false
        },

        async onInit(promise) {
            // show loading indicator
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
            finally {
                // hide loading indicator
            }
        },
        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const { boundingBox: { x, y, width, height } } = detectedCode

                ctx.lineWidth = 2
                ctx.strokeStyle = '#007bff'
                ctx.strokeRect(x, y, width, height)
            }
        },
        cancel() {
            this.søk = false
            this.guid = ''
            this.focusInput()
            const currentRoute = this.$router.currentRoute;
            if (currentRoute.path !== '/mottak') {
                this.$router.push({ name: 'Mottak' });
            }
           
        },
        focusInput() {
            this.$refs.qrCodeReader.focus();
        }
    },
    watch: {
        '$route'(to) {
            this.$nextTick(() => {
            let self = this
            if (to.name === 'Mottak') {
                self.focusInput()
            }
        })

    }
}
};
</script>
  