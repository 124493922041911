const FishingTypeEnum = Object.freeze([
    { id: 1, name: "Sportsfiske" },
    { id: 2, name: "Høstfiske" },
    { id: 3, name: "Sjølaksefiske" },
    { id: 4, name: "Annet" },
    { id: 5, name: "Ungfiskundersøkelse" },
    { id: 6, name: "Lysfiske" },
    { id: 7, name: "Gytefisktelling" },
    { id: 8, name: "Ukjent" },
    { id: 9, name: "Elbåt-fiske" }
 ]);
  
  export default FishingTypeEnum;