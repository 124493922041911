<template>
  <div class="searchResult">
    <section>
      <div class="notification is-info is-light">
              <p class="is-info" v-html="searchResultMessage"></p>
            </div>

            <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" v-show="animals.length > 0">
              <thead>
                <tr>
                  <th>Fra</th>
                  <th>Art</th>
                  <th>Kjønn</th>
                  <th>Alder/Fødselsår</th>
                  <th>Dato registrert *</th>
                  <th>Kategori</th>
                  <th>Sted</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="animal in animals" v-bind:key=animal.id>
                  <td> {{ getDataKilde(animal.source) }}</td>
                  <td>{{ animal.speciesName }}</td>
                  <td>{{ animal.sexName }}</td>
                  <td>{{ animal.ageName }}{{ animal.birthYear }}</td>
                  <td> {{ animal.dateCreated | formatDate }}</td>
                  <td>{{ animal.individualCategoryName  }} </td>
                  <td>  {{ animal.municipalityName }} {{ animal.wildRaindeerAreaName }}</td>
                  <td>
                    <a v-if="animal.id > 0" @click="showIndividual(animal.id)">Vis</a>
                    <a v-if="animal.id == 0" @click="createIndividual(animal)">Bruk</a> 
                  </td>
                </tr>
              </tbody>
            </table>
            <span class="help"
                >* Dødsdato for dyr felt under jakt og funndato/dødsdato for
                fallvilt. For merkede dyr er dette dato dyret ble merket (første
                gang).
              </span>
        
    </section>
  </div>
</template>

<script>
import api from "@/api/basicApi.js";
export default {
  name: "IndividSearchResult",
  data: function() {
    return {
      externalId: ""
    };
  },
  props: {
    animals: {
      type: Array,
      required: true
    }
  },

  computed: {
    searchResultMessage() {
      
      return "Søket ga " + this.animals.length + " treff";
    },
    
  },
  methods: {
    showIndividual(id) {
      this.$router.push({name: "Individual", params: {individualId: id}})
    },
    getDataKilde(kildeId) {
      if (kildeId == 1) return "NINA";
      if (kildeId == 2) return "Hjorteviltregisteret";
      return "Ukjent"
    },
    createIndividual(animal) {
      api.saveData({ url: "Individ/SaveIndividual", data: animal }).then(res => {
        this.$router.push({name: "Individual", params: {individualId: res.data}})
      })
    }
  }
};
</script>

<style scoped></style>
