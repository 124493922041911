<template>
    <div>
        <div v-if="isEdit">
            <ValidationObserver ref="form">
                <div class="column is-6">
                    <div class="box">
                        <div class="columns">
                            <div class="column">
                                <h2 class="subtitle">
                                    <span v-show="unit.id == 0">Registrer ny enhet</span>
                                    <span v-show="unit.id > 0">Endre enhet</span>
                                </h2>
                            </div>
                            <div class="column">
                                <button v-show="unit.id == 0"
                                    class="button is-small is-text ml-2 has-text-primary is-pulled-right"
                                    @click="copyUnitFromPrevious()"> <i class="fas fa-copy"></i>
                                    <span class="ml-1">Kopier fra forrige</span>
                                </button>
                            </div>

                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <button class="button is-info is-small" @click="registerUnit">
                                    <span class="icon is-small">
                                        <i class="fa fa-save"></i>
                                    </span>
                                    <span>Lagre </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button is-small" @click="cancel"><span class="icon is-small">
                                        <i class="fa fa-times"></i>
                                    </span>
                                    <span>Avbryt</span>
                                </button>
                            </div>
                        </div>

                        <div class="field">
                            <div class="control">
                                <label class="label">Art</label>
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <v-select class="style-chooser" placeholder="Velg art eller skriv for å søke"
                                        v-bind:options="dropdowns.species" v-model="unit.speciesId"
                                        :reduce="item => item.id" label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                Ingen resultat for <em>{{ search }}</em>.
                                            </template>
                                            <em v-else style="opacity: 0.5">Ingen arter funnet</em>
                                        </template>
                                    </v-select>
                                    <p class="help is-danger">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <field-select label-text="Prøvetype" v-model="unit.sampleMaterialId"
                                        v-bind:options="dropdowns.sampleMaterials" />
                                    <p class="help is-danger">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="field">
                            <div class="control">
                                <field-select v-show="unit.speciesId != 3" label-text="Kommune"
                                    v-model="unit.municipalityId" v-bind:options="dropdowns.municipalities" />
                                <field-select v-show="unit.speciesId === 3" label-text="Villreinområde"
                                    v-model="unit.wildRaindeerAreaId" v-bind:options="dropdowns.wildRaindeerArea" />
                            </div>
                        </div>

                        <div class="field">
                            <div class="control">
                                <field-select label-text="Prosjekt" v-model="unit.projectId"
                                    v-bind:options="dropdowns.projects" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Prosjektleder</label>
                            <div class="control has-icons-right">

                                <input class="input" type="text" v-model="unit.projectLeader">
                                <span class="icon is-small is-right">
                                    <i class="fas fa-user"></i>
                                </span>
                            </div>
                            <p class="help">Navn på ansvarlig eier</p>
                        </div>
                        <div class="field">
                            <label class="label">Registrert av</label>
                            <div class="control has-icons-right">

                                <input class="input" type="text" v-model="unit.createdBy">
                                <span class="icon is-small is-right">
                                    <i class="fas fa-user"></i>
                                </span>
                            </div>
                            <p class="help">Fylles ut hvis lagt inn av andre enn prosjektleder</p>
                        </div>
                        <div class="field">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label class="label">Dato sjekket inn</label>
                                <flat-pickr v-model="unit.checkinDate"></flat-pickr>
                                <span class="help is-italic">År-måned-dag</span>
                                <p class="help is-danger">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>

                        <div class="field">
                            <div class="control">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <field-select label-text="Lagringssted" v-model="unit.storageLocationId"
                                        v-bind:options="dropdowns.storageLocations" />
                                    <p class="help is-danger">{{ errors[0] }}</p>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="field">
                            <div class="control">
                                <field-select label-text="Lagringsenhet" v-model="unit.storageLocationDetailId"
                                    v-bind:options="storageLocationDetails" />
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Kommentar</label>
                            <div class="control">
                                <input class="input" type="text" placeholder="Text input" v-model="unit.comment">
                            </div>
                        </div>

                        <div class="field" v-show="unit.isCheckedOut">
                            <label class="label">Dato sjekket ut</label>
                                <flat-pickr v-model="unit.checkoutDate"></flat-pickr>
                                <span class="help is-italic">År-måned-dag</span>
                        </div>

                        <div class="field" v-show="unit.isCheckedOut">
                            <label class="label">Sjekket ut av</label>
                            <div class="control">
                                <input class="input" type="text" placeholder="Text input" v-model="unit.checkedOutBy">
                            </div>
                        </div>
                        <div class="field is-grouped mt-5">
                            <div class="control">
                                <button class="button is-info is-small" @click="registerUnit">
                                    <span class="icon is-small">
                                        <i class="fa fa-save"></i>
                                    </span>
                                    <span>Lagre </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button is-small" @click="cancel"><span class="icon is-small">
                                        <i class="fa fa-times"></i>
                                    </span>
                                    <span>Avbryt</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </div>

        <div v-if="!isEdit && unit.id > 0">
            <div class="column is-6">
                <div class="box">
                    <div class="columns">
                        <div class="column is-6">
                            <h2 class="subtitle">Info om prøve(r):</h2>
                        </div>
                        <div class="column is-6 ">

                            <button class="button is-small is-danger is-outlined is-pulled-right" @click="doDelete">
                                <span class="icon is-small">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span>Slett</span>
                            </button>
                            <button class="button is-small is-info is-outlined is-pulled-right mr-1" @click="isEdit = true">
                                <span class="icon is-small">
                                    <i class="fas fa-pen"></i>
                                </span>
                                <span>Endre</span>
                            </button>

                        </div>
                    </div>


                    <div class="content">

                        <div class="table is-borderless is-narrow">
                            <tbody>
                                <tr>
                                    <td><strong>Dato inn: </strong></td>
                                    <td>{{ unit.checkinDate | formatDate }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Art: </strong></td>
                                    <td>{{ unit.speciesName }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Prøvetype: </strong></td>
                                    <td>{{ unit.sampleMaterialName }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Kommune: </strong></td>
                                    <td>{{ unit.municipalityId }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Villreinområde: </strong></td>
                                    <td>{{ unit.wildRaindeerAreaAreaName }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Kommentar: </strong></td>
                                    <td>{{ unit.comment }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Prosjekt: </strong></td>
                                    <td>{{ unit.projectName }} {{ unit.projectDescription }}</td>
                                </tr>

                                <tr>
                                    <td><strong>Prosjektleder: </strong></td>
                                    <td>{{ unit.projectLeader }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Reg. inn av: </strong></td>
                                    <td>{{ unit.createdBy }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Lagringssted: </strong></td>
                                    <td>{{ unit.storageLocationName }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Lagringsenhet: </strong></td>
                                    <td>{{ unit.storageLocationDetailName }}</td>
                                </tr>
                                <tr v-show="unit.checkoutDate != null">
                                    <td><strong><strong>Dato ut: </strong> </strong></td>
                                    <td>{{ unit.checkoutDate | formatDate }}</td>
                                </tr>
                                <tr v-show="unit.checkoutDate != null">
                                    <td><strong>Reg. ut av: </strong></td>
                                    <td>{{ unit.checkedOutBy }}</td>
                                </tr>

                            </tbody>

                        </div>

                    </div>
                    <div class="mt-6">
                        <button class="button is-info mr-2" @click="showCheckoutModal()"
                            :disabled="unit.checkoutDate != null">
                            <span class="icon">
                                <i class="fa fa-check"></i>
                            </span>
                            <span>Sjekk ut</span>
                        </button>
                        <button class="button is-default" @click="cancel">
                            <span class="icon">
                                <i class="fa fa-times"></i>
                            </span>
                            <span>Avbryt</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        <checkout-dialogue ref="checkoutDialogue" @checkedOutBy="setCheckedOutBy"></checkout-dialogue>
    </div>
</template>
  
<script>

import api from "@/api/basicApi.js";
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
import CheckoutMottakSamplesDialogue from "../modals/CheckoutMottakSamplesDialogue.vue";

export default {

    data: function () {
        return {
            søk: false,
            unit: {
                id: 0,
                speciesId: null,
                sampleMaterialId: null,
                municipalityId: null,
                wildRaindeerAreaId: null,
                projectLeader: null,
                projectId: null,
                comment: null,
                checkinDate: new Date(),
                storageLocationId: null,
                storageLocationDetailId: null,
                createdBy: ''
            },
            dropdowns: {},
            isEdit: null,
            storageLocationDetails: [],
            checkedOutBy: ''

        }
    },
    props: {
        guid: {
            type: String,
            required: false
        }
    },
    components: {
        "confirm-dialogue": ConfirmDialogue,
        "checkout-dialogue": CheckoutMottakSamplesDialogue
    },
    mounted() {
        let self = this
        if (self.guid != null) {
            self.searchByGuid()
        }

        api.fetchData({ url: "Mottak/GetDropdownsForMottak" }).then(res => {
            self.dropdowns = res.data;
        })
    },

    methods: {
        cancel() {
            this.$emit("clear");
        },
        searchByGuid() {
            let self = this;
            api.fetchData({ url: "Mottak/GetUnitByGuid/" + self.guid })
                .then(res => {
                    if (res.data == null) {
                        self.isEdit = true
                    } else {
                        self.isEdit = false
                        self.unit = res.data
                    }
                })
        },
        registerUnit() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else {
                    let self = this;
                    self.unit.guid = self.guid;
                    api.saveData({ url: "Mottak/SaveUnit/", data: self.unit })
                        .then(() => {
                            self.$emit("clear");
                        });
                }
            })
            

        },
        checkoutUnit() {
            let self = this;
            api.saveData({ url: "Mottak/CheckoutUnit", data: { id: self.unit.id, checkedOutBy: self.checkedOutBy } })
                .then(() => {
                    self.$emit("clear");
                });
        },
        finnProsjektleder() {
            let self = this;
            api.fetchData({ url: "Mottak/FinnProsjektleder/" + self.unit.projectId }).then(res => {
                self.unit.projectLeader = res.data
            })
        },
        doDelete() {
            let self = this
            this.$refs.confirmDialogue.show({
                title: 'Slett prøve(r)',
                message: 'Er du sikker på at du vil slette prøven?',
                okButton: 'Slett',
            }).then((result) => {
                if (result) {
                    api.deleteData({ url: "Mottak/DeleteUnit/" + self.unit.id }).then(() => {
                        self.$emit("clear");
                    })
                }
            })
        },
        getStorageLocationDetails() {
            let self = this
            api.fetchData({ url: "Mottak/GetStorageDetailsForLocation/" + this.unit.storageLocationId }).then(res => {
                self.storageLocationDetails = res.data
            })
        },

        getSampleMaterialForSpeciesType() {
            let self = this
            if (this.unit.speciesId != null) {
                api.fetchData({ url: "Mottak/GetSampleMaterialForSpeciesType/" + this.unit.speciesId }).then(res => {
                    self.dropdowns.sampleMaterials = res.data
                })
            }
        },

        setCheckedOutBy(checkedOutBy) {
            this.checkedOutBy = checkedOutBy
        },
        showCheckoutModal() {
            this.$refs.checkoutDialogue.show().then((choosen) => {
                if (choosen) {
                    this.checkoutUnit()
                }
            })
        },
        copyUnitFromPrevious() {
            let self = this;
            api.fetchData({ url: "Mottak/CopyFromPrevious" }).then(res => {
                self.unit.speciesId = res.data.speciesId
                self.unit.sampleMaterialId = res.data.sampleMaterialId
                self.unit.municipalityId = res.data.municipalityId
                self.unit.wildRaindeerAreaId = res.data.wildRaindeerAreaId
                self.unit.projectLeader = res.data.projectLeader
                self.unit.projectId = res.data.projectId
                self.unit.checkinDate = res.data.checkinDate
                self.unit.comment = res.data.comment
                self.unit.storageLocationId = res.data.storageLocationId
                self.unit.storageLocationDetailId = res.data.storageLocationDetailId
                self.unit.createdBy = res.data.createdBy
            })
        }
    },
    watch: {
        'unit.storageLocationId'(newVal) {
            if (newVal != null) {
                this.getStorageLocationDetails()
            }
        },
        'unit.speciesId'(newVal) {
            if (newVal != null) {
                this.getSampleMaterialForSpeciesType()
            }
        },
        'isEdit'(newVal) {
            if (newVal) {
                this.getSampleMaterialForSpeciesType()
            }
        }
    }
};
</script>

<style>
.table.is-borderless td,
.table.is-borderless th {
    border: 0;
}
</style>
  