<template>
  <div>
    <section class="section is-default is-fullheight">
      <div class="container has-text-centered">
        
        <div class="card-image has-text-centered">
          <figure class="image is-64x64 is-inline-block">
                <img
                  class=""
                  src="NINA_logo_emblem.png"
                />
              </figure>
            </div> 

        <h1 class="title has-text-black">NINA biologiske prøver</h1>
        <h2 class="subtitle">Biobase</h2>
        
        <hr class="login-hr" />
        <div class="column is-6 is-offset-3">
          <div class="box">
         
            <form @submit.prevent="handleSubmit">
              <div class="field">
                <label for="username" class="label">Brukernavn</label>
                <div class="control">
                  <input
                    type="text"
                    v-model="username"
                    name="username"
                    class="input has-text-centered"
                    :class="{ 'help is-danger': submitted && !username }"
                  />
                </div>
                <div v-show="submitted && !username" class="help is-danger">
                  Username is required
                </div>
              </div>
              <div class="field">
                <label htmlFor="password" class="label">Passord</label>
                <div class="control">
                  <input
                    type="password"
                    v-model="password"
                    name="password"
                    class="input has-text-centered"
                    :class="{ 'help is-danger': submitted && !password }"
                  />
                </div>
                <div v-show="submitted && !password" class="help is-danger">
                  Password is required
                </div>
              </div>
              <!-- <div class="field">
                  <label class="checkbox">
                    <input type="checkbox" />
                    Remember me
                  </label>
                </div> -->
              <button class="button is-block is-info is-fullwidth mt-5">
                Logg inn
              </button>
            </form>
          </div>
          <p class="has-text-grey">
            <router-link to="/registerUser">Opprett bruker</router-link>
            &nbsp;·&nbsp;
            <router-link to="/forgotPassword">Glemt passord</router-link> &nbsp;
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/basicApi.js";
import { EventBus } from "@/event-bus.js";
export default {
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      loggingIn: false
    };
  },

  created() {
    // reset login status
    // this.logout();
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;
      if (username && password) {
        //let body = { username: username, password: password };
        let body = { email: username, password: password };
        api.login(body).then(res => {
          console.log(res.data);
          EventBus.$emit("loggedIn");
          this.$router.push({ name: "Home" });
        });
      }
    }
  }
};
</script>
