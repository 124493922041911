<template>
  <div class="search">
    <section class="section">
      <div class="container">
        <h1 class="title">Søk etter prøver for individ</h1>
         <!-- Komponent-kandidat -->

         <div class="control mb-4">
          <label class="checkbox mr-2">
            <input type="checkbox" name="hjortevilt" id="hjortevilt" v-model="filters.globalTypes" :value="1"> Hjortevilt 
          </label>

          <label class="checkbox mr-2">
            <input type="checkbox" name="rovdyr" id="rovdyr" v-model="filters.globalTypes" :value="2"> Rovdyr
          </label>
          
          <label class="checkbox mr-2">
            <input type="checkbox" id="fugler" name="fugler" v-model="filters.globalTypes" :value="6"> Fugler 
          </label> 
          
         <label class="checkbox mr-2">          
            <input type="checkbox" id="pattedyr" name="pattedyr" v-model="filters.globalTypes" :value="7"> Pattedyr
          </label>
          
          <label class="radio">
          <input type="radio" name="skjellprøve" disabled>
          Fiskeskjell
          </label>
      
        </div>

        <div class="columns">
          <div class="column is-6-desktop">
            <div class="field is-grouped">
              <p class="control is-expanded">
                <input
                  class="input"
                  type="text"
                  v-model="externalId"
                  placeholder="Søk på strekkode eller individ-id"
                />
              </p>
              <p class="control">
                <a class="button is-info" @click="setExternalId()">
                  <span class="icon">
                    <i class="fas fa-search"></i>
                  </span>
                  <span>Søk</span>
                </a>
              </p>
            </div>
          </div>
          <div class="column is-offset-3-desktop is-3-desktop">
            <div class="control">
              <button
                class="button is-outlined is-info is-pulled-right ml-2"
                @click="exportCsv()"
              >
                <span class="icon is-small">
                  <i class="fas fa-download"></i>
                </span>
                <span>Last ned</span>
              </button>
              <button @click="clearAllFilters()" class="button is-pulled-right">
                Nullstil filter
              </button>
            </div>
          </div>
          <div class="is-clearfix"></div>
        </div>
        <div class="columns" v-show="manyHits">
          <div class="column">
            <div class="notification is-info is-light is-fullwidth">
              Listen viser de
              <strong>{{ samplesReturned }} nyeste</strong> prøvene av totalt
              <strong>{{ samplesTotal }}</strong> prøver. Bruk filterne for å
              søke på ønskede kriterier eller eksporter prøvene til fil.
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-full">
            <div class="table-container">
              <table class="table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="is-size-7">
                  <tr>
                    <th>
                      <abbr title="Ekstern eller intern id">Individ id</abbr>
                    </th>
                    <th>
                      <span
                        >Art
                        <a
                          @click="openModal('speciesId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive('speciesId'),
                            'has-text-grey': !filterActive('speciesId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>
                      <filter-modal
                        v-bind:options="species"
                        @input="input"
                        v-show="showSpecies"
                        filterName="speciesId"
                        @close="close"
                        ref="speciesFilter"
                      ></filter-modal>
                    </th>
                    <th>
                      <span
                        >Kommune
                        <a
                          @click="openModal('municipalityId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive('municipalityId'),
                            'has-text-grey': !filterActive('municipalityId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>
                      <filter-modal
                        v-model="municipality"
                        v-bind:options="municipalities"
                        @input="input"
                        v-show="showMunicipality"
                        filterName="municipalityId"
                        @close="close"
                        ref="municipalityFilter"
                      ></filter-modal>
                    </th>
                    <th>
                      <span
                        >Villreinområde
                        <a
                          @click="openModal('wildRaindeerAreaId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive(
                              'wildRaindeerAreaId'
                            ),
                            'has-text-grey': !filterActive('wildRaindeerAreaId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>
                      <filter-modal
                        v-bind:options="raindeerAreas"
                        @input="input"
                        v-show="showRaindeerArea"
                        filterName="wildRaindeerAreaId"
                        @close="close"
                        ref="raindeerAreaFilter"
                      ></filter-modal>
                    </th>

                    <th>
                      <span
                        >Prøvetype
                        <a
                          @click="openModal('sampleMaterialId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive(
                              'sampleMaterialId'
                            ),
                            'has-text-grey': !filterActive('sampleMaterialId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>

                      <filter-modal
                        v-bind:options="sampleMaterials"
                        @input="input"
                        v-show="showSampleMaterial"
                        filterName="sampleMaterialId"
                        @close="close"
                        ref="sampleMaterialFilter"
                      ></filter-modal>
                    </th>

                    <th>
                      <span
                        >Lagring
                        <a
                          @click="openModal('storageLocationId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive(
                              'storageLocationId'
                            ),
                            'has-text-grey': !filterActive('storageLocationId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>
                      <filter-modal
                        v-bind:options="storageLocations"
                        @input="input"
                        v-show="showStorageLocation"
                        filterName="storageLocationId"
                        @close="close"
                        ref="storageFilter"
                      ></filter-modal>
                    </th>
                    <th>
                      <span>
                        Prosjekt
                        <a
                          @click="openModal('projectId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive('projectId'),
                            'has-text-grey': !filterActive('projectId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>
                      <filter-modal
                        v-bind:options="projects"
                        @input="input"
                        v-show="showProjects"
                        filterName="projectId"
                        @close="close"
                        ref="projectFilter"
                      ></filter-modal>
                      <abbr title="Prosjekt"> </abbr>
                    </th>

                    <th>
                      <span>
                        <a
                          @click="openModal('ownerId')"
                          class="is-pulled-right"
                          v-bind:class="{
                            'has-text-success': filterActive('ownerId'),
                            'has-text-grey': !filterActive('ownerId')
                          }"
                          ><i class="fas fa-filter"></i></a
                      ></span>
                      <filter-modal
                        v-bind:options="owners"
                        @input="input"
                        v-show="showOwners"
                        filterName="ownerId"
                        @close="close"
                        ref="ownerFilter"
                      ></filter-modal>
                      <abbr title="Oppdragsgiver">OG</abbr>
                    </th>
                    <th>
                      <abbr title="Lagringsmedium">Medium</abbr>
                    </th>
                    <th>Mottatt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sample in samples" :key="sample.id">
                    <td>
                      <router-link
                        :to="{
                          name: 'Individual',
                          params: { individualId: sample.individualId }
                        }"
                      >
                        <span class="has-text-weight-semibold">{{
                          sample.individualExternalId
                        }}</span>
                        <span
                          class="has-text-weight-semibold"
                          v-show="
                            sample.individualInternalId !== '0' &&
                              sample.individualInternalId !== null
                          "
                        >
                          {{ sample.individualInternalId }}</span
                        >
                      </router-link>
                    </td>
                    <td>{{ sample.individualSpeciesName }}</td>
                    <td>
                      {{ sample.individualMunicipalityName }}
                      {{ sample.individualMunicipalityId }}
                    </td>
                    <td>{{ sample.individualWildRaindeerAreaName }}</td>
                    <td>{{ sample.sampleMaterialName }}</td>
                    <td>{{ sample.storageLocationName }}</td>
                    <td>
                      <div
                        class="has-tooltip-info has-tooltip-arrow has-tooltip-right has-tooltip-multiline"
                        v-bind:value="sample.projectName"
                        v-bind:data-tooltip="sample.projectDescription"
                      >
                        {{ sample.projectName }}
                      </div>
                    </td>

                    <td>
                      <div
                        v-show="sample.numberOfOwners > 0"
                        class="has-tooltip-info has-tooltip-arrow has-tooltip-right has-tooltip-multiline"
                        v-bind:value="sample.numberOfOwners"
                        v-bind:data-tooltip="sample.ownerNames"
                      >
                        {{ sample.numberOfOwners }}
                      </div>
                    </td>
                    <td>{{ sample.storageMediumName }}</td>
                    <td>{{ sample.dateReceived | formatDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FilterModal from "@/components/modals/FilterModal.vue";
import api from "@/api/basicApi.js";
import { PROVEBASE_CONFIG } from "@/../config.js";

export default {
  data: function() {
    return {
      filters: {
        externalId: null,
        speciesId: null,
        municipalityId: null,
        storageLocationId: null,
        sampleMaterialId: null,
        wildRaindeerAreaId: null,
        projectId: null,
        ownerId: null,
        globalTypes: [1, 2, 6, 7]
      },
      externalId: "",
      speciesName: "",
      globalType: "",
      municipality: "",
      showMunicipality: false,
      showSpecies: false,
      showRaindeerArea: false,
      showSampleMaterial: false,
      showGlobalType: false,
      showStorageLocation: false,
      showProjects: false,
      showOwners: false,
      isActive: false,
      samples: [],
      samplesReturned: 0,
      samplesTotal: 0,
      species: [],
      sampleMaterials: [],
      municipalities: [],
      raindeerAreas: [],
      storageLocations: [],
      projects: [],
      owners: []
      
    };
  },
  components: {
    "filter-modal": FilterModal
  },
  computed: {
    filterByAllCriterias() {
      return this.getByExternalId(
        this.getByCounty(
          this.getBySpeciesName(
            this.getByGlobalType(this.samples, this.filters["globalType"]),
            this.filters["species"]
          ),
          this.filters["municipality"]
        ),
        this.filters["externalId"]
      );
    },
    manyHits() {
      return this.samplesReturned >= 100;
    }
  },
  mounted() {
    this.getFilters();
    this.getSamples();
    
  },
  watch: {
    filters: {
      handler(newFilter) {
        if (newFilter != null) {
          this.getSamples()
          this.getFilters()
        }
      },
      deep: true
    }
  },
  methods: {
    getSamples() {
      let self = this
      api.postData({ url: "Search/GetSamples", data: self.filters }).then(res => {
      self.samples = res.data.samples;
      self.samplesReturned = res.data.samplesReturned;
      self.samplesTotal = res.data.samplesTotal;
    });
    },
    getFilters() {
      let self = this;
    api.postData({ url: "Search/GetSearchFilters", data: self.filters.globalTypes }).then(res => {
      self.species = res.data.species;
      self.municipalities = res.data.municipalities;
      self.raindeerAreas = res.data.wildRaindeerArea;
      self.projects = res.data.projects;
      self.sampleMaterials = res.data.sampleMaterials;
      self.storageLocations = res.data.storageLocations;
      self.owners = res.data.owners;
    });
    },
    getByExternalId(samples, externalId) {
      const search = externalId.trim();
      if (!search.length) return samples;
      return samples.filter(item => item.externalId.indexOf(search) > -1);
    },
    getBySpeciesName(samples, speciesName) {
      if (!speciesName) return samples;
      return samples.filter(item => item.species === speciesName);
    },
    getByGlobalType(samples, globalType) {
      if (!globalType) return samples;
      return samples.filter(item => item.type === globalType);
    },
    getByCounty(samples, municipalityName) {
      if (!municipalityName) return samples;
      return samples.filter(item => item.municipality === municipalityName);
    },
    setExternalId() {
      this.filters["externalId"] = this.externalId;
    },
    toggleCounty() {
      this.showMunicipality = !this.showMunicipality;
    },
    openModal(filterName) {
      this.closeModals();
      if (filterName == "speciesId") {
        this.showSpecies = true;
      }
      if (filterName == "municipalityId") {
        this.showMunicipality = true;
      }
      if (filterName == "wildRaindeerAreaId") {
        this.showRaindeerArea = true;
      }
      if (filterName == "sampleMaterialId") {
        this.showSampleMaterial = true;
      }
      if (filterName == "globalTypeId") {
        this.showGlobalType = true;
      }
      if (filterName == "storageLocationId") {
        this.showStorageLocation = true;
      }
      if (filterName == "projectId") {
        this.showProjects = true;
      }
      if (filterName == "ownerId") {
        this.showOwners = true;
      }
    },
    isSelected() {
      this.isActive = true;
      this.toggleCounty();
    },
    input(filter, value) {
      this.closeModals();
      this.filters[filter] = value;
    },
    closeModals() {
      this.showMunicipality = false;
      this.showSpecies = false;
      this.showRaindeerArea = false;
      this.showGlobalType = false;
      this.showStorageLocation = false;
      this.showProjects = false;
      this.showSampleMaterial = false;
      this.showOwners = false;
    },
    close() {
      this.closeModals();
    },
    clearAllFilters() {
      this.closeModals();
      this.$refs.projectFilter.clearSelected();
      this.$refs.ownerFilter.clearSelected();
      this.$refs.storageFilter.clearSelected();
      this.$refs.municipalityFilter.clearSelected();
      this.$refs.raindeerAreaFilter.clearSelected();
      this.$refs.speciesFilter.clearSelected();
      this.$refs.sampleMaterialFilter.clearSelected();

      this.filters["sampleMaterialId"] = null;
      this.filters["externalId"] = null;
      this.filters["municipalityId"] = null;
      this.filters["storageLocationId"] = null;
      this.filters["wildRaindeerAreaId"] = null;
      this.filters["speciesId"] = null;
      this.filters["projectId"] = null;
      this.filters["ownerId"] = null;
    },
    filterActive(value) {
      if (this.filters[value] !== "" && this.filters[value] !== null) {
        return true;
      } else {
        return false;
      }
    },
    exportCsv() {
      api.fetchData({ url: "Search/GetEncryptedToken" }).then(res => {
        var tokenFromServer = res.data;
        let queryString = "encryptedToken=" + tokenFromServer;

        if (this.filters["externalId"] != null) {
          queryString += "&externalId=" + this.filters["externalId"];
        }
        if (this.filters["sampleMaterialId"] != null) {
          queryString +=
            "&sampleMaterialId=" + this.filters["sampleMaterialId"];
        }
        if (this.filters["municipalityId"] != null) {
          queryString += "&municipalityId=" + this.filters["municipalityId"];
        }
        if (this.filters["storageLocationId"] != null) {
          queryString +=
            "&storageLocationId=" + this.filters["storageLocationId"];
        }
        if (this.filters["wildRaindeerAreaId"] != null) {
          queryString +=
            "&wildRaindeerAreaId=" + this.filters["wildRaindeerAreaId"];
        }
        if (this.filters["speciesId"] != null) {
          queryString += "&speciesId=" + this.filters["speciesId"];
        }
        if (this.filters["projectId"] != null) {
          queryString += "&projectId=" + this.filters["projectId"];
        }
        if (this.filters["ownerId"] != null) {
          queryString += "&ownerId=" + this.filters["ownerId"];
        }
                
        this.filters.globalTypes.forEach(gt => {
          queryString += "&globalTypes=" + gt 
        })
     

        window.location.href =
          `${PROVEBASE_CONFIG.API_URL}/` + "search/ExportAsCsv?" + queryString;
      });
    }
  }
};
</script>
<style scoped>
.filter-card {
  position: absolute;
  z-index: 100;
}
</style>
