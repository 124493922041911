<template>
  <div>
    <ValidationObserver ref="form">
      <form @submit.prevent="save, copySampleFromPrevious">
        <div class="register">

          <div class="container box mt-5">

            <div class="columns">
              <div class="column">
                <h2 class="subtitle is-inline-block">Registrer prøve</h2>
                <button class="button is-small is-text ml-2" @click="copySampleFromPrevious()"> <i
                    class="fas fa-copy"></i>
                  <span class="ml-1">Kopier fra nyeste</span></button>
              </div>
            </div>

            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <div v-show="sample.mottakUnitId > 0">
                    <label class="label">Koblet til mottak</label>
                    <div class="control">
                      <router-link :to="{ name: 'Mottak', params: { guid: sample.mottakUnitGuid } }" target='_blank'>Vis
                        prøve i mottak</router-link>
                      <button class="button is-small is-text ml-2" @click="unlink"> <i class="fa fa-unlink mr-1"></i>
                        Fjern kobling</button>
                    </div>
                  </div>
                  <div v-show="sample.mottakUnitId === null">
                    <label class="label">Koble til mottak av prøver</label>
                    <div class="control">
                      <button class="button is-success is-small is-outlined" @click="showLinkModal = true">
                        <span class="icon is-small">
                          <i class="fa fa-link"></i>
                        </span>
                        <span>Koble</span>
                      </button>
                    </div>
                    <div class="help">
                      Knytt til mottaket på Grovlab for å sikrer sporbarhet fra ankomst til permanent lagring.
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="control">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <field-select label-text="Prøvetype" v-model="sample.sampleMaterialId" v-bind:options="sampleMaterial"
                      :error="errors[0]" />
                  </ValidationProvider>
                </div>
              </div>

              <div class="column is-4">
                <div class="control">
                  <label class="label">Prøve id</label>
                  <input class="input" type="text" v-model="sample.customId" placeholder="Strekkode, QR-kode, egen id" />
                  <div class="help">Fylles inn hvis prøven er unikt merket</div>
                </div>
              </div>

              
            </div>

            <div class="columns">
              <div class="column is-half">
                <div class="field">
                  <label class="label">Notater</label>
                  <div class="control">
                    <textarea class="textarea" placeholder="Merknader etc" v-model="sample.notes" />
                  </div>
                </div>
              </div>
              <div class="column is-2">
                <div class="field">
                  <label class="label">Prøve oppbrukt</label>
                  <div class="control">
                    <label class="radio">
                      <input type="radio" name="foobar" v-bind:value="true" v-model="sample.empty" />
                      Ja
                    </label>
                    <label class="radio">
                      <input type="radio" name="foobar" v-bind:value="false" checked v-model="sample.empty" />
                      Nei
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <div class="field">
                  <label class="label">Prøvetakingsdato</label>
                  <ValidationProvider>
                    <flat-pickr v-model="sample.sampleDate"></flat-pickr>
                    <span class="help is-italic">År-måned-dag</span>
                    <div class="help">
                      Dato prøven ble tatt fra dyret.
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="column is-3">
                <div class="field">
                  <label class="label">Dato mottatt</label>
                  <ValidationProvider>
                    <flat-pickr v-model="sample.dateReceived"></flat-pickr>
                    <div class="help is-italic">År-måned-dag</div>
                    <div class="help">
                      Dato prøven ble mottatt dersom denne er forskjellig
                      fra prøvetakingsdato.
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="column is-3">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <field-select label-text="Lagringsmedium" v-model="sample.storageMediumId"
                    v-bind:options="storageMediums" :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <div class="field">
                  <label class="label">Dato lagret på medium</label>
                  <ValidationProvider>
                    <flat-pickr v-model="sample.dateStoredOnMedium"></flat-pickr>
                    <div class="help is-italic">År-måned-dag</div>
                    <div class="help">
                      Dato prøven ble lagret på medium dersom denne er
                      forskjellig fra prøvetakingsdato.
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column is-3">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <field-select label-text="Lagringssted" v-model="sample.storageLocationId"
                    v-bind:options="storageLocations" :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <field-input label-text="Samlet av" placeholder="Navn" v-model="sample.collectedBy" />
              </div>

              <div class="column is-3">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <field-select label-text="Prosjekter" v-model="sample.projectId" v-bind:options="projects"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <p class="subtitle">For merka dyr</p>
            <p class="subtitle is-6">
              Fylles ut hvis prøvested er forskjellig fra merkeplass
            </p>
            <div class="columns">
              <div class="column is-3">
                <div class="control">
                  <field-select label-text="UTM Sone" v-model="sample.utmZone" v-bind:options="utmZones" />
                </div>
              </div>
              <div class="column is-3">
                <field-input label-text="Øst" v-model="sample.utmEast" inputType="number" />
              </div>
              <div class="column is-3">
                <field-input label-text="Nord" v-model="sample.utmNorth" inputType="number" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <field-input label-text="Latitude" v-model="sample.latitude" />
              </div>
              <div class="column is-3">
                <field-input label-text="Longitude" v-model="sample.longitude" />
              </div>
              <div class="column is-3">
                <field-select label-text="Kommune" v-model="sample.municipalityId" v-bind:options="kommuner" />
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <button type="submit" @click="save" class="button is-info">
                  <span class="icon is-small">
                    <i class="fa fa-save"></i>
                  </span>
                  <span>Lagre prøve</span>
                </button>

                <button type="button" class="button is-default ml-2" v-on:click="doCancel">
                  <span class="icon is-small">
                    <i class="fa fa-times"></i>
                  </span>
                  <span>Avbryt</span>
                </button>
                <button v-show="sample.id > 0" type="button" class="button is-default ml-2" v-on:click="doDelete">
                  <span class="icon is-small">
                    <i class="fa fa-times"></i>
                  </span>
                  <span>Slett</span>
                </button>

              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    <link-mottak :checkedOutSamples="checkedOutSamples" @showModal="showModal" @link="link"
      v-if="showLinkModal"></link-mottak>

  </div>
</template>

<script>
import api from "@/api/basicApi.js";
import LinkMottak from "./modals/LinkMottak.vue";
import ConfirmDialogue from './modals/ConfirmDialogue.vue';

export default {

  data: function () {
    return {

      utmZones: [
        { id: 32, name: "UTM 32" },
        { id: 33, name: "UTM 33" },
        { id: 34, name: "UTM 34" },
        { id: 35, name: "UTM 35" }
      ],
      utmZone: "",
      kommuner: [],
      showModalConfirm: false,
      date: null,
      projects: [],
      sampleMaterial: [],
      storageMediums: [],
      storageLocations: [],
      showLinkModal: false,
      checkedOutSamples: [],
      sample: {}
    };
  },

  props: {
    globalType: {
      type: Number,
      required: true
    },
    sampleId: {
      type: Number,
      required: false
    }
  },

  components: {
    "confirm-dialogue": ConfirmDialogue,
    "link-mottak": LinkMottak
  },
  mounted() {

    let self = this;
    api.fetchData({ url: "Individ/Kommuner" }).then(res => {
      self.kommuner = res.data;
    });
     
    if (self.sampleId > 0) {
      self.getSample()
    } else {
      self.newSample()
    }

    api.fetchData({ url: "Prove/RegisterSample/" + this.globalType }).then(res => {
      self.projects = res.data.projects;
      self.sampleMaterial = res.data.sampleMaterials;
      self.storageMediums = res.data.storageMediums;
      self.storageLocations = res.data.storageLocations;
      self.projects = res.data.projects;

      //Blir denne rett i alle tilfeller? Hjortevilt-spesifikt
      //res.data.data.sampleDate = self.animal.dateCreated;
      // res.data.data.projectId = self.animal.projectId;

    });


  },
  computed: {
    getUrlMottak() {
      return "mottak/" + this.sample.mottakUnitGuid
    }
  },
  methods: {

    save() {
      let self = this;
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        api.saveData({ url: "Prove/SaveSample", data: self.sample }).then(res => {
          console.log(res);
          self.$refs.form.reset();
          self.$emit("sampleUpdated");
        });
      });
    },
    getSample() {
      if (this.sampleId > 0) {
        api.fetchData({ url: 'Prove/GetSample/' + this.sampleId }).then(res => {
          this.sample = res.data
        })
      }
    },
    newSample() {
      this.sample = {
        id: 0,
        individualId: this.$route.params.individualId,
        mottakUnitId: null,
        sampleMaterialId: null, 
        projectId: null,
        storageLocationId: null
      }
    },

    doDelete() {
      let self = this
      self.$refs.confirmDialogue.show({
        title: 'Slett prøve',
        message: 'Er du sikker på at du vil slette prøven?',
        okButton: 'Slett',
      }).then((result) => {
        if (result) {
          api.deleteData({ url: "Prove/Delete/" + self.sample.id }).then(res => {
            if (res) {
              self.$emit("sampleUpdated");
            }
          })
        }
      })
    },
    doCancel() {
      this.$refs.confirmDialogue.show({
        title: 'Avbryt',
        message: 'Er du sikker på at du ønsker å avbryte?',
        okButton: 'Ja',
        cancelButton: 'Nei'
      }).then((result) => {
        if (result) {
          this.$emit("cancel")
        }
      })
    },
    unlink() {
      this.sample.mottakUnitId = null
    },
    focusInput() {
      this.$refs.searchId.focus();
    },
    copySampleFromPrevious() {
      let self = this;
      api.fetchData({ url: "Prove/GetPreviousSampleData" }).then(res => {
        self.sample = {
          id: 0,
          individualId: self.$route.params.individualId
        }
        this.copySampleCommonFields(res.data)
        self.sample.storageMediumId = res.data.storageMediumId
        self.sample.dateStoredOnMedium = res.data.dateStoredOnMedium
        self.sample.empty = res.data.empty
        self.sample.notes = res.data.notes
        self.sample.sampleDate = res.data.sampleDate

      })
    },
    showModal(value) {
      this.showLinkModal = value;
    },
    link(id) {
      let self = this
      api.fetchData({ url: "Mottak/GetSampleDataForMottakEnhet/" + id }).then(res => {
        this.copySampleCommonFields(res.data)
        self.sample.mottakUnitId = res.data.mottakUnitId
        self.sample.mottakUnitGuid = res.data.mottakUnitGuid

      })
    },
    copySampleCommonFields(sampleFrom) {
      this.sample.collectedBy = sampleFrom.collectedBy
      this.sample.dateReceived = sampleFrom.dateReceived
      this.sample.projectId = sampleFrom.projectId
      this.sample.sampleMaterialId = sampleFrom.sampleMaterialId
      this.sample.storageLocationId = sampleFrom.storageLocationId
      
    }


  },
  watch: {
    'sampleId'(nySampleId) {
      if (nySampleId > 0) {
        this.getSample();
      } else {
        this.newSample();
      }
    }
  }
};
</script>
