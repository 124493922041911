<template>
    <popup-modal ref="popup">

        <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button class="delete" aria-label="close" @click="_cancel"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">{{ message }}</div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" type="button" @click="_confirm">
            <span class="icon is-small">
              <i class="fa fa-check"></i>
            </span>
            <span>{{ okButton }}</span>
          </button>
          <button class="button" type="button" @click="_cancel">
            <span class="icon is-small">
              <i class="fa fa-times"></i>
            </span>
            <span>{{ cancelButton }}</span>
          </button>
        </footer>
      </div>
    </div>    
    </popup-modal>
</template>
<script>

import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Avbryt', // text for cancel button
        
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
           
        }
    },
}
</script>