<template>
  <div class="searchResult">
    <section>
      <div class="columns">

      </div>

      <div>
        <div class="columns">
          <div class="column is-3">
            <div class="field">
              <label class="label">Kilde</label>
              <div class="control">{{ getSource }}</div>
            </div>
          </div>
          <div class="column is-3" v-show="animal.speciesGlobalTypeId == 1">
            <div class="field">
              <label class="label">Ekstern id</label>
              <div class="control">{{ animal.externalId }}</div>
              <div class="control" v-show="!isLinked && isInternalAnimal && isAdminOrHjorteviltRolle">
                <button class="button is-success is-small is-outlined" @click="showLinkModal = true">
                  <span class="icon is-small">
                    <i class="fa fa-link"></i>
                  </span>
                  <span>Koble</span>
                </button>
              </div>
              <div class="control" v-show="isLinked && isInternalAnimal && isAdminOrHjorteviltRolle">
                <i class="fa fa-link "></i><span> </span>
                <a v-show="isAdminOrHjorteviltRolle && isInternalAnimal" @click="removeLink()"
                  class="has-text-weight-bold has-text-black ml-2 is-underlined">Fjern kobling</a>
              </div>
            </div>
          </div>
          <div class="column is-3" v-show="animal.internalId !== null">
            <div class="field">
              <label class="label">Intern id</label>
              <div class="control">{{ animal.internalId }}</div>
            </div>
          </div>
          <div class="column is-3" v-show="animal.fallvilt_Journalnr !== null">
            <div class="field">
              <label class="label">Intern id</label>
              <div class="control">{{ animal.fallvilt_Journalnr }}/{{ animal.fallvilt_Journalaar }}</div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Dato registrert * </label>
              <div class="control">
                {{ animal.dateCreated | formatDate }}
              </div>
            </div>
          </div>
          <div class="column">
            <a @click="edit" v-show="animal.source == 1" class="button is-info is-small is-outlined">
              <span class="icon is-small">
                <i class="fa fa-pen"></i>
              </span>
              <span>Endre</span>
            </a>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Art</label>
              <div class="control">
                {{ animal.speciesName }}
              </div>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label class="label">Kjønn</label>
              <div class="control">
                {{ animal.sexName }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Alder/Fødselsår</label>
              <div class="control">
                {{ animal.ageName }} {{ animal.birthYear }} {{ animal.fallvilt_Age }}
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Type</label>
              <div class="control">
                {{ animal.individualCategoryName }}
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3" v-show="animal.speciesGlobalTypeId == 1 || animal.speciesGlobalTypeId == 7">
            <div class="field">
              <label class="label">Kommune</label>
              <div class="control">
                {{ animal.municipalityId }} {{ animal.municipalityName }}
              </div>
            </div>
          </div>
          <div class="column is-3" v-show="animal.speciesGlobalTypeId == 1">
            <div class="field">
              <label class="label">Villreinområde</label>
              <div class="control">{{ animal.wildRaindeerAreaName }}</div>
            </div>
          </div>
          <span class="help" v-show="animal.speciesGlobalTypeId == 1">* Dødsdato for dyr felt under jakt og
            funndato/dødsdato for fallvilt.
            For merkede dyr er dette dato dyret ble merket (første gang).
          </span>
        </div>

        <div class="columns" v-show="animal.speciesGlobalTypeId == 6">
          <div class="column is-3">
            <div class="field">
              <label class="label">UTM </label>
              <div class="control">
                Sone {{ animal.utmZone }} Øst: {{ animal.east }} Nord: {{ animal.north }}
              </div>
            </div>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label">Latitude/Longitude</label>
              <div class="control">{{ animal.latitude }} / {{ animal.longitude }}</div>
            </div>
          </div>
          <div class="column is-3">
            <div class="field">
              <label class="label">Funnsted</label>
              <div class="control">{{ animal.place }}</div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <link-individuals @showModal="showModal" @link="link" v-if="showLinkModal"></link-individuals>
  </div>
</template>

<script>
import LinkIndividuals from "@/components/modals/LinkIndividuals.vue";
import api from "@/api/basicApi.js";
export default {
  name: "IndividualInfo",
  data: function () {
    return {
      showLinkModal: false
    };
  },
  props: {
    animal: {
      type: Object,
      required: true
    }
  },
  components: {
    "link-individuals": LinkIndividuals
  },
  computed: {
    getSource() {
      if (this.animal.source === 2) {
        return "Hjorteviltrgisteret";
      } else if (this.animal.source === 1) {
        return "NINA biologisk prøvebase";
      } else if (this.animal.source === 3) {
        return "Fallviltbasen";
      } else {
        return "Ikke satt";
      }
    },
    isLinked() {
      if (this.animal.externalId !== undefined && this.animal.externalId !== null) {
        return true;
      }
      return false;
    },
    isInternalAnimal() {
      return this.animal.source === 1;
    },
    isAdminOrHjorteviltRolle() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.accountRoles.includes(1) || user.accountRoles.includes(2)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    showModal(value) {
      this.showLinkModal = value;
    },
    link(externalId) {
      api
        .saveData({
          url:
            "Individ/LinkIndividual/?id=" +
            this.animal.id +
            "&externalId=" +
            externalId
        })
        .then(res => {
          if (res.data !== null) {
            this.animal = res.data;
            this.isLinked = true;
          }
        });
    },
    removeLink() {
      api
        .saveData({
          url: "Individ/RemoveLinkIndividual/?id=" + this.animal.id
        })
        .then(res => {
          if (res.data !== null) {
            this.animal = res.data;
            this.isLinked = false;
          }
        });
    },
    edit() {
      this.$root.$emit("edit");
    }
  }
};
</script>

<style scoped></style>
