<template>
  <div class="createIndividual">
    <section>
      <div class="columns">
        <div class="column">
          <h2 class="subtitle is-inline-block">{{ title }}</h2>

          <button v-show="animal.id == null" class="button is-small is-text ml-2" @click="copyFromPreviousIndividual"> <i
              class="fas fa-copy"></i> <span class="ml-1">Kopier fra nyeste</span>
          </button>
        </div>
      </div>
      <ValidationObserver ref="form">
        <form @submit.prevent="save, copySampleFromPrevious">
          <fieldset>
            <div class="columns">
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Gruppe *" v-model="globalTypeId" v-bind:options="globalTypes"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Art *" v-model="animal.speciesId" v-bind:options="species" placeholder="Art"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-input label-text="Intern id *" v-model="animal.internalId" :error="errors[0]" />
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <div class="field">
                  <label class="label">Dato registrert</label>
                  <flat-pickr v-model="animal.dateCreated"></flat-pickr>
                  <span class="help" v-show="globalTypeId === 1 ">Dødsdato for dyr felt under jakt og funndato/dødsdato for fallvilt. For merkede dyr er dette dato dyret ble merket (første gang).
        </span>
                </div>
              </div>
              <div class="column is-3" v-show="globalTypeId === 6 || globalTypeId === 7">
                <div class="field">
                  <label class="label">Dato funnet</label>
                  <flat-pickr v-model="animal.dateFound"></flat-pickr>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Kjønn *" v-model="animal.sexId" v-bind:options="sex" placeholder="Kjønn"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Alder *" v-model="animal.ageId" v-bind:options="ages" placeholder="Alder"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Kategori *" v-model="animal.individualCategoryId" v-bind:options="categories"
                    placeholder="Kategori" :error="errors[0]" />
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3" v-show="globalTypeId === 1 || globalTypeId === 7">
                <field-select label-text="Kommune" v-bind:options="kommuner" v-model="animal.municipalityId" />
              </div>

              <div class="column is-3" v-show="globalTypeId === 1">
                <field-select label-text="Villreinområde" v-model="animal.wildRaindeerAreaId"
                  v-bind:options="raindeerAreas" />
              </div>

            </div>
            <div v-show="globalTypeId === 6">
              <div class="columns">
                <div class="column is-3">
                  <div class="control">
                    <field-select label-text="UTM Sone" v-model="animal.utmZone" v-bind:options="utmZones" />
                  </div>
                </div>
                <div class="column is-3">
                  <field-input label-text="Øst" v-model="animal.east" inputType="number" />
                </div>
                <div class="column is-3">
                  <field-input label-text="Nord" v-model="animal.north" inputType="number" />
                </div>
              </div>
              <div class="columns">
                <div class="column is-3">
                  <field-input label-text="Latitude" v-model="animal.latitude" inputType="number"/>
                </div>
                <div class="column is-3">
                  <field-input label-text="Longitude" v-model="animal.longitude" inputType="number" />
                </div>
                <div class="column is-3">
                  <field-input label-text="Funnsted" v-model="animal.place" />
                </div>
              </div>
            </div>
          </fieldset>

          <div class="columns mt-5">
            <div class="column">
              <button type="submit" @click="save" class="button is-info">
                <span class="icon is-small">
                  <i class="fa fa-save"></i>
                </span>
                <span>Lagre</span>
              </button>

              <button type="button" class="button is-default ml-2" v-on:click="cancel">
                <span class="icon is-small">
                  <i class="fa fa-times"></i>
                </span>
                <span>Avbryt</span>
              </button>
            </div>
          </div>

        </form>
      </ValidationObserver>
    </section>
  </div>
</template>

<script>
import api from "@/api/basicApi.js";
export default {
  name: "CreateEditIndividual",
  data: function () {
    return {
      utmZones: [
        { id: 32, name: "UTM 32" },
        { id: 33, name: "UTM 33" },
        { id: 34, name: "UTM 34" },
        { id: 35, name: "UTM 35" }
      ],
      isFound: false,
      externalId: "",
      kommuner: [],
      raindeerAreas: [],
      species: [],
      sex: [],
      ages: [],
      categories: [],
      globalTypeId: null,
      globalTypes: []
    };
  },
  props: {
    animal: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
  },
  mounted() {
    var self = this;
    api.fetchData({ url: "Individ/GetGlobalTypes/" }).then(res => {
      self.globalTypes = res.data;
    });

    if (this.animal.speciesGlobalTypeId > 0) {
      this.globalTypeId = this.animal.speciesGlobalTypeId
    }

  },
  methods: {
    getDropdowns() {
      let self = this
      api.fetchData({ url: "Individ/GetDropdownsForGlobalType/" + this.globalTypeId }).then(res => {
        self.kommuner = res.data.kommuner;
        self.raindeerAreas = res.data.villreinomrader;
        self.sex = res.data.sex;
        self.ages = res.data.ages,
          self.categories = res.data.categories
      });
    },

    getSpecies() {
      let self = this
      api.fetchData({ url: "Individ/GetSpecies/" + this.globalTypeId }).then(res => {
        self.species = res.data;
      });
    },

    copyFromPreviousIndividual() {
      var self = this
      api.fetchData({ url: "Individ/GetInfoFromPrevious" }).then(res => {
        self.animal.speciesId = res.data.speciesId
        self.animal.sexId = res.data.sexId
        self.animal.ageId = res.data.ageId
        self.animal.individualTypeId = res.data.individualTypeId
        self.animal.municipalityId = res.data.municipalityId
        self.animal.wildRaindeerAreaId = res.data.wildRaindeerAreaId
      })
    },

    save() {
      let self = this;
      self.$refs.form.validate().then(success => {
        if (!success) {
          return;
        } else {
          api.saveData({ url: "Individ/SaveIndividual", data: self.animal }).then(res => {
            if (self.$route.name == 'FindOrCreateIndividual') {
              self.$router.push({ name: "Individual", params: { individualId: res.data } })
            } else {
              self.$root.$emit("closeCreateEdit");
            }

          })
        }
      })

    },
    cancel() {
      if (this.$route.name == 'FindOrCreateIndividual') {
        this.$root.$emit("cancelCreate");
      } else {
        this.$root.$emit("closeCreateEdit");
      }

    }
  },
  watch: {
    'globalTypeId'(newValue) {
      if (newValue != null) {
        this.getSpecies()
        this.getDropdowns()
      }

    }
  }
};
</script>

<style scoped></style>
