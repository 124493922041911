<template>
  <div class="searchResult">
    <section>
      <div class="notification is-info is-light">
              <p class="is-info" v-html="searchResultMessage"></p>
            </div>
      <div class="columns" v-show="isFound">
        <div class="column">
          <div class="box">
            <h2 class="subtitle">Informasjon om individ</h2>
            <div>
              <div class="columns">
                <div class="column is-3">
                  <div class="field">
                    <label class="label">Ekstern id</label>
                    <div class="control">{{ animal.externalId }}</div>
                  </div>
                </div>
                <div class="column is-3">
                  <div class="field">
                    <label class="label">Intern id</label>
                    <div class="control">{{ animal.internalId }}</div>
                  </div>
                </div>
                <div class="column is-3">
                  <div class="field">
                    <label class="label">Dato registrert * </label>
                    <div class="control">
                      {{ animal.dateCreated | formatDate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Art</label>
                    <div class="control">
                      {{ animal.speciesName }}
                    </div>
                  </div>
                </div>

                <div class="column">
                  <div class="field">
                    <label class="label">Kjønn</label>
                    <div class="control">
                      {{ animal.sexName }}
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Alder/Fødselsår</label>
                    <div class="control">
                      {{ animal.ageName }} {{ animal.birthYear }}
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label">Type</label>
                    <div class="control">
                      {{ animal.individualCategoryName }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column  is-3">
                  <div class="field">
                    <label class="label">Kommune</label>
                    <div class="control">
                      <span v-show="animal.source == 1">
                        {{ animal.municipalityId }}
                        {{ animal.municipalityName }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="column is-3">
                  <div class="field">
                    <label class="label">Villreinområde</label>
                    <div class="control">
                      {{ animal.wildRaindeerAreaName }}
                    </div>
                  </div>
                </div>
              </div>
              <span class="help"
                >* Dødsdato for dyr felt under jakt og funndato/dødsdato for
                fallvilt. For merkede dyr er dette dato dyret ble merket (første
                gang).
              </span>
            </div>
          </div>
        </div>
        
        <div class="column" v-if="samples">
          <div class="box">
            <h2 class="subtitle">Prøver for individ</h2>

            <p v-show="samples.length === 0">
              Ingen prøver registrert for individ
            </p>
            <div v-show="samples.length > 0">
              <table class="table is-fullwidth is-bordered is-striped">
                <thead>
                  <tr>
                    <th>Prøvetype</th>
                    <th>Lagring</th>
                    <th>Prøvetakingsdato</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sample in samples" :key="sample.id">
                    <td>{{ sample.sampleMaterialName }}</td>
                    <td>{{ sample.storageLocationName }}</td>
                    <td>{{ sample.sampleDate | formatDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "IndividSearchResult",
  data: function() {
    return {
      externalId: ""
    };
  },
  props: {
    animal: {
      type: Object,
      required: true
    },
    isFound: {
      type: Boolean,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    samples: {
      type: Array,
      required: false
    }
  },

  computed: {
    searchResultMessage() {
      if (!this.isFound) {
        return "Ingen treff";
      } else {
        if (this.animal.source === 1) {
          if (this.isEdit) {
            return "Kilde: <strong>NINA biologisk prøvebase</strong>";
          }
          return "Ditt søk ga <strong>1 treff</strong> i NINAs biologiske prøvebase";
        } else if (this.animal.source === 2) {
          if (this.isEdit) {
            return "Kilde: <strong> Hjorteviltregisteret </strong>";
          }
          return "Ditt søk ga <strong>1 treff</strong> i Hjorteviltregisteret";
        } else {
          return "";
        }
      }
    }
  }
};
</script>

<style scoped></style>
