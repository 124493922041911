import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import "bulma/css/bulma.css";
import "./assets/css/custom-bulma.css";
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";
import "animate.css";
import * as VeeValidate from "vee-validate";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, confirmed } from "vee-validate/dist/rules";
import moment from "moment";
import api from "@/api/basicApi.js";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import FlatPickrWrapper from '@/components/globals/flatpickr-wrapper.vue';
import SmartTable from 'vuejs-smart-table'


Vue.component('flat-pickr', FlatPickrWrapper);
Vue.component('v-select', vSelect)

extend("required", {
  ...required,
  message: "Må fylles ut"
});
extend("email", {
  ...email,
  message: "Du må angi en gyldig e-post adresse"
});

extend("min", {
  ...min,
  message: "Passordet må være minimun 6 tegn"
});

extend("confirmed", {
  ...confirmed,
  message: "Passord og bekreft passord er ikke like"
});

import FieldInput from "@/components/globals/field-input.vue";
import FieldSelect from "@/components/globals/field-select.vue";

Vue.config.productionTip = false;
Vue.component("field-input", FieldInput);
Vue.component("field-select", FieldSelect);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});

Vue.use(VeeValidate, { events: "", errorBagName: "vErrors" });
Vue.use(SmartTable)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

api.refreshToken();
