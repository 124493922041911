<template>
    <div class="control has-icons-right">
      <vue-flatpickr :value="value" :config="mergedConfig" @input="updateValue" class="input" :disabled="disabled"></vue-flatpickr>
      <span class="icon is-small is-right has-text-primary" >
        <i class="fas fa-calendar"></i>
      </span>
    </div>
</template>

<script>
import VueFlatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/light.css';
import { Norwegian } from "flatpickr/dist/l10n/no.js";

export default {
  components: {
    VueFlatpickr,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        date: null,
        locale: Norwegian,
        wrap: true,
        allowInput: true
      }),
    },
    value: [Date, Array, String, Number],
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    updateValue(selectedDates) {
      this.$emit('input', selectedDates);
    },
  },
  computed: {
    mergedConfig() {
      return { ...this.config };
    },
  },
};
</script>

