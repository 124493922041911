<template>
  <div>
    <section class="section">
      <div class="container">
        <h1 class="title">Finn individ</h1>
        <div class="columns">
          <div class="column is-6">
            <div class="field is-grouped">
              <p class="control is-expanded">
                <input class="input" type="text" ref="searchId" v-on:keyup.enter="searchIndividualById" v-model="searchId"
                  placeholder="Strekkode, internt reg.nr NINA etc." />
              </p>
              <p class="control">
                <button class="button is-info" @click="searchIndividualById()" type="button">
                  <span class="icon is-small">
                    <i class="fa fa-search"></i>
                  </span>
                  <span>Søk</span>
                </button>
              </p>
              <p class="control">
              <button class="is-info button" @click="createIndivid()" type="button">
                <span class="icon is-small">
                  <i class="fa fa-plus"></i>
                </span>
                <span>Ny </span>
              </button>
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
        <div class="register">
          <div class="container">
            <div class="columns" v-if="isSearch">
              <div class="column">
                <search-result :animals="animals"></search-result>
              </div>
            </div>
            <div class="columns" v-if="createNewIndividual">
              <div class="column">
                <div class="box">
                  <create-edit-individual :animal="animal" :title="'Opprett individ'"></create-edit-individual>
                </div>
              </div>
            </div>
          </div>
        </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

  </div>
</template>

<script>

import SearchResult from "@/components/IndividualSearchResult.vue";
import CreateEditIndividual from "@/components/CreateEditIndividual.vue";
import api from "@/api/basicApi.js";
import ConfirmDialogue from '../components/modals/ConfirmDialogue.vue';

export default {

  data: function () {
    return {
      searchId: "",
      animal: {},
      animals: [],
      createNewIndividual: false,
      isSearch: false,
      showModalConfirm: false
    };
  },

  components: {
    "search-result": SearchResult,
    "create-edit-individual": CreateEditIndividual,
    "confirm-dialogue": ConfirmDialogue
  },
  mounted() {
    let self = this;

    self.$root.$on("closeCreateEdit", function () {
      self.createNewIndividual = false;
    });

    self.$root.$on("cancelCreate", function () {
      self.doCancel()
    });

    this.focusInput();


  },
  computed: {},
  methods: {
    createIndivid() {
      this.animal = {};
      this.createNewIndividual = true;
      this.isSearch = false;
    },
    searchIndividualById() {
      let self = this;
      this.isSearch = true;
      this.createNewIndividual = false;
      api.fetchData({ url: "Individ/SearchById/" + self.searchId }).then(res => {
          if (res.data.length == 0) {
            self.animals = [];
          } else {
            self.animals = res.data
          }
        });
    },

    doCancel() {
      this.$refs.confirmDialogue.show({
        title: 'Avbryt',
        message: 'Er du sikker på at du ønsker å avbryte?',
        okButton: 'Ja',
        cancelButton: 'Nei'
      }).then((result) => {
        if (result) {
          this.createNewIndividual = false;
         this.animal = {}
         this.focusInput()
        }
      })
    },

    focusInput() {
      this.$refs.searchId.focus();
    }
  }
};
</script>
