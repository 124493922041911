import { PROVEBASE_CONFIG } from "@/../config.js";
import axios from "axios";
import * as bulmaToast from "bulma-toast";
import router from "@/router";
import { EventBus } from "@/event-bus.js";

export function GetAPI_URL() {PROVEBASE_CONFIG.API_URL}

const axiosInst = axios.create();
axiosInst.defaults.withCredentials = true;

axiosInst.interceptors.request.use(
  function(config) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const token = user.jwtToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

let successMessage = {
  message: "",
  type: "is-success",
  position: "bottom-right",
  duration: 3000,
  dismissible: true,
  pauseOnHover: true
};
let errorMessage = {
  message: "",
  type: "is-danger",
  position: "bottom-right",
  duration: 3000,
  dismissible: true,
  pauseOnHover: true,
  animate: { in: "fadeIn", out: "fadeOut" }
};

let refreshTokenTimeout;
export default {
  fetchData(payload) {
    var loader = document.getElementById("loader");
    loader.classList.add("is-active");
    let body = {};
    let self = this;
    if (payload && payload.body) {
      body = Object.assign({}, payload.body, body);
    }
    return new Promise((resolve, reject) => {
      axiosInst
        .get(`${PROVEBASE_CONFIG.API_URL}/${payload.url}`, body)
        .then(function(response) {
          resolve(response);
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            EventBus.$emit("loggedOut");
            self.stopRefreshTokenTimer();
            localStorage.removeItem("user");

            router.push({ path: "/login" });
          } else {
            errorMessage.message = "Det oppstod en feil";
            bulmaToast.toast(errorMessage);
          }
          reject(error);
        })
        .finally(function() {
          loader.classList.remove("is-active");
        });
    });
  },
  saveData(payload) {
    var loader = document.getElementById("loader");
    loader.classList.add("is-active");
    return new Promise((resolve, reject) => {
      axiosInst
        .post(`${PROVEBASE_CONFIG.API_URL}/${payload.url}`, payload.data)
        .then(response => {
          resolve(response);
          successMessage.message = "Lagring vellykket";
          bulmaToast.toast(successMessage);
        })
        .catch(error => {
          reject(error);
          
          errorMessage.message = "Det oppstod en feil";
          if (error.response && error.response.data ) {
            errorMessage.message += ": " + error.response.data.message
          }
          bulmaToast.toast(errorMessage);
        })
        .finally(function() {
          loader.classList.remove("is-active");
        });
    });
  },
  postData(payload) {
    var loader = document.getElementById("loader");
    loader.classList.add("is-active");
    return new Promise((resolve, reject) => {
      axiosInst
        .post(`${PROVEBASE_CONFIG.API_URL}/${payload.url}`, payload.data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          errorMessage.message = "Det oppstod en feil";
          bulmaToast.toast(errorMessage);
        })
        .finally(function() {
          loader.classList.remove("is-active");
        });
    });
  },
  deleteData(payload) {
    var loader = document.getElementById("loader");
    loader.classList.add("is-active");
    return new Promise((resolve, reject) => {
      axiosInst
        .post(`${PROVEBASE_CONFIG.API_URL}/${payload.url}`, payload.data)
        .then(response => {
          resolve(response);
          successMessage.message = "Sletting vellykket";
          bulmaToast.toast(successMessage);
        })
        .catch(error => {
          reject(error);
          errorMessage.message = "Det oppstod en feil";
          bulmaToast.toast(errorMessage);
        })
        .finally(function() {
          loader.classList.remove("is-active");
        });
    });
  },
  login(body) {
    let self = this;
    var loader = document.getElementById("loader");
    loader.classList.add("is-active");

    return new Promise((resolve, reject) => {
      axiosInst
        .post(`${PROVEBASE_CONFIG.API_URL}` + "/Accounts/authenticate", body)
        .then(res => {
          if (res.data.jwtToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("user", JSON.stringify(res.data));
            resolve(res.data);
            self.startRefreshTokenTimer();
            successMessage.message = "Innlogging vellykket";
            bulmaToast.toast(successMessage);
          } else {
            reject();
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            errorMessage.message = error.response.data.message;
            this.logout();
          } else {
            errorMessage.message = "Det oppstod en feil ved innlogging";
          }
          reject(error);
          bulmaToast.toast(errorMessage);
        })
        .finally(function() {
          loader.classList.remove("is-active");
        });
    });
  },
  logout() {
    let self = this;
    return new Promise(resolve => {
      axiosInst
        .post(`${PROVEBASE_CONFIG.API_URL}` + "/Accounts/revoke-token", {})
        .then(response => {
          self.stopRefreshTokenTimer();
          localStorage.removeItem("user");
          resolve(response);
        })
        .catch(function() {
          self.stopRefreshTokenTimer();
          localStorage.removeItem("user");
        });
    });
  },
  downloadFile(payload) {
    return new Promise((resolve, reject) => {
    axiosInst({
      url: `${PROVEBASE_CONFIG.API_URL}/${payload.url}`,
      method: 'GET',
      responseType: 'blob'
    })
    .then(response => {
        resolve(response);
    })
    .catch(error => {
      reject(error);
      errorMessage.message = "Det oppstod en feil";
      bulmaToast.toast(errorMessage);
    })
  })   
  },

  startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    let self = this;
    //console.log("start refreash token timer: " + Date.now());
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const jwtToken = JSON.parse(atob(user.jwtToken.split(".")[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      //const timeout = 30000;
      refreshTokenTimeout = setTimeout(function() {
        self.refreshToken();
      }, timeout);
    }
  },

  refreshToken() {
    let self = this;
    //console.log("refresh token called: " + Date.now());
    return axiosInst
      .post(`${PROVEBASE_CONFIG.API_URL}` + "/Accounts/refresh-token", {
        withCredentials: true
      })
      .then(user => {
        // publish user to subscribers and start timer to refresh token
        localStorage.setItem("user", JSON.stringify(user.data));
        self.startRefreshTokenTimer();
        //console.log("refresh token received: " + Date.now());
        return user;
      })
      .catch(error => {
        console.log(error + "error refresh token: " + Date.now());
      });
  },

  stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
  }

  
};


