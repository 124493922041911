<template>
  <popup-modal ref="bulkEditDialog">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Endre mange prøver</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <div class="modal-card-body">


          <div class="notification is-info is-light">Velg hvilke felt som skal endres for valgte prøver</div>

          <p v-show="hasValidationErrors" class="help is-danger mb-4">Valgte felt mangler verdi</p>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editSpecies = !editSpecies" />
              <label class="label is-inline-block">Art</label>
            </div>
            <div class="field-body">

              <div class="field" v-if="editSpecies">

                <v-select class="style-chooser is-small" placeholder="Velg art eller skriv for å søke"
                  v-bind:options="dropdowns.species" v-model="unit.speciesId" :reduce="item => item.id" label="name">
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Ingen resultat for <em>{{ search }}</em>.
                    </template>
                    <em v-else style="opacity: 0.5">Ingen arter funnet</em>
                  </template>
                </v-select>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editProvetype = !editProvetype" />
              <label class="label is-inline-block">Prøvetype</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editProvetype">
                <field-select v-model="unit.sampleMaterialId" v-bind:options="dropdowns.sampleMaterials" />
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editProject = !editProject" />
              <label class="label is-inline-block">Prosjekt</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editProject">
                <field-select v-model="unit.projectId" v-bind:options="dropdowns.projects" />
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editLeader = !editLeader" />
              <label class="label is-inline-block">Prosjektleder</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editLeader">
                <div class="control has-icons-right">

                  <input class="input" type="text" v-model="unit.projectLeader">
                  <span class="icon is-small is-right">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
                <p class="help">Navn på ansvarlig eier</p>
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editRegisteredBy = !editRegisteredBy" />
              <label class="label is-inline-block">Registrert av</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editRegisteredBy">
                <div class="control has-icons-right">
                  <input class="input" type="text" v-model="unit.createdBy">
                  <span class="icon is-small is-right">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
                <p class="help">Fylles ut hvis lagt inn av andre enn prosjektleder</p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="checkinDate = !checkinDate" />
              <label class="label is-inline-block">Dato sjekket inn</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="checkinDate">
                <flat-pickr v-model="unit.checkinDate"></flat-pickr>
                <span class="help is-italic">År-måned-dag</span>
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editStorageLocation = !editStorageLocation" />
              <label class="label is-inline-block">Lagringssted</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editStorageLocation">
                <field-select v-model="unit.storageLocationId" v-bind:options="dropdowns.storageLocations" />
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2"
                @click="editStorageLocationDetail = !editStorageLocationDetail" />
              <label class="label is-inline-block">Lagringsenhet</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editStorageLocationDetail">
                <field-select v-model="unit.storageLocationDetailId" v-bind:options="storageLocationDetails" />
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editComment = !editComment" />
              <label class="label is-inline-block">Kommentar</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editComment">
                <input class="input" type="text" placeholder="" v-model="unit.comment">
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="checkoutDate = !checkoutDate" />
              <label class="label is-inline-block">Dato sjekket ut</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="checkoutDate">
                <flat-pickr v-model="unit.checkoutDate"></flat-pickr>
                <span class="help is-italic">År-måned-dag. </span>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="checkedOutBy = !checkedOutBy" />
              <label class="label is-inline-block">Sjekket ut av</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="checkedOutBy">
                <input class="input" type="text" placeholder="" v-model="unit.checkedOutBy">
              </div>
            </div>
          </div>
          
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="undoCheckout = !undoCheckout" v-model="unit.undoCheckout"/>
              <label class="label is-inline-block">Angre utsjekk</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="undoCheckout">
                <span class="help is-italic">Angre utsjekk </span>
              </div>
            </div>
          </div>


        </div>

        <footer class="modal-card-foot">
          <button class="button is-info" @click="bulkEdit"><span class="icon is-small">
              <i class="fa fa-save"></i>
            </span>
            <span>Endre</span> 
          </button>
          <button class="button" @click="close()">
            <span class="icon is-small">
              <i class="fa fa-times"></i>
            </span>
            <span>Avbryt</span>
          </button>
        </footer>
      </div>
    </div>
  </popup-modal>
</template>

<script>

import api from "@/api/basicApi.js";
import PopupModal from './PopupModal.vue'
export default {
  name: "BulkEditMottakSamples",
  data: function () {
    return {
      unit: {},
      dropdowns: [],
      storageLocationDetails: [],
      editSpecies: false,
      editProvetype: false,
      editProject: false,
      editLeader: false,
      editRegisteredBy: false,
      checkinDate: false,
      editStorageLocation: false,
      editStorageLocationDetail: false,
      editComment: false,
      hasValidationErrors: false,
      checkoutDate: false,
      checkedOutBy: false,
      undoCheckout: false
    };
  },
  components: { PopupModal },

  props: {},
  mounted() {
    let self = this
    this.hasValidationErrors = false;
    api.fetchData({ url: "Mottak/GetDropdownsForMottakBulkEdit" }).then(res => {
      self.dropdowns = res.data;
    })

  },
  methods: {
    show() {
      this.$refs.bulkEditDialog.open();
    },
    bulkEdit() {
      let hasSelectedFieldsValues = this.validateEditValues()
      if (hasSelectedFieldsValues) {
        
        this.$emit('valuesToUpdate', this.unit)
        this.$refs.bulkEditDialog.close()
        this.initBulkEdit()
      } else {
        this.hasValidationErrors = true
      }

    },
    close() {
      this.$refs.bulkEditDialog.close()
      this.initBulkEdit()
    },

    validateEditValues() {
      if (this.editSpecies && this.unit.speciesId == null) return false
      if (this.editProvetype && this.unit.sampleMaterialId == null) return false
      if (this.editProject && this.unit.projectId == null) return false
      if (this.editLeader && this.unit.projectLeader == null) return false
      if (this.editRegisteredBy && this.unit.createdBy == null) return false
      if (this.checkinDate && this.unit.checkinDate == null) return false
      if (this.checkoutDate && this.unit.checkoutDate == null) return false
      if (this.checkedOutDate && this.unit.checkedOutDate == null) return false
      if (this.editStorageLocation && this.unit.storageLocationId == null) return false
      if (this.editStorageLocationDetail && this.unit.storageLocationDetailId == null) return false
      if (this.editComment && this.unit.comment == null) return false
      return true
    },

    initBulkEdit() {
      this.unit = {},
        this.editSpecies = false,
        this.editProvetype = false,
        this.editProject = false,
        this.editLeader = false,
        this.editRegisteredBy = false,
        this.checkinDate = false,
        this.checkoutDate = false,
        this.checkedOutBy = false,
        this.editStorageLocation = false,
        this.editStorageLocationDetail = false,
        this.editComment = false,
        this.hasValidationErrors = false,
        this.undoCheckout = false
    },
    getStorageLocationDetails() {
      let self = this
      api.fetchData({ url: "Mottak/GetStorageDetailsForLocation/" + this.unit.storageLocationId }).then(res => {
        self.storageLocationDetails = res.data
      })
    },
  },
  watch: {
    'unit.storageLocationId'(newVal) {
      if (newVal != null) {
        this.getStorageLocationDetails()
      }
    }
  }
};
</script>

<style scoped>
.field-label {
  flex-grow: 2;
  text-align: left;
}
</style>
