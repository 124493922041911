<template>
    <div class="container">

        <section class="section">
            <FylkeVassdrag :model="fylkeVassdrag"></FylkeVassdrag>
        </section>

        <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
            <thead class="has-background-info-light">
                <tr>
                    <th>Fylke</th>
                    <th>Vassdrag</th>
                    <th>År</th>
                    <th>Fisketider laks</th>
                    <th>Fisketid sjøørret</th>
                    <th>Fisketid sjørøye</th>
                    <th >Spesielle bestemmelser</th>
                    <th style="width: 200px">Kommentar</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(elvFisketid, index) in filteredList"
                    :key="elvFisketid.elvFisketidId">
                    <td> {{ elvFisketid.fylke }}</td>
                    <td>{{ elvFisketid.objektnavn }}</td>
                    <td> {{ elvFisketid.year }}</td>
                    <td>{{ elvFisketid.fisketid_laks }} </td>
                    <td>{{ elvFisketid.fisketid_sjoorret }}</td>
                    <td>{{ elvFisketid.fisketid_sjoroye }}</td>
                    <td>{{ elvFisketid.spesielle_bestemmelser }}</td>
                    <td>
                        <textarea class="textarea" v-model="elvFisketid.comment" :disabled="!isEditable(index)"></textarea>
                    </td>
                    <td>
                        <a v-show="!isEditable(index)" @click="editComment(index)">Endre</a>
                        <a v-show="isEditable(index)" @click="saveComment(index)">Lagre</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>

import api from "@/api/basicApi.js";
import FylkeVassdrag from '../grouped/FylkeVassdrag.vue';

export default {

    data: function () {
        return {
            fylkeVassdrag: {
                fylkeId: 0,
                vassdragId: 0
            },
            vassdragListe: [],
            fisketider: [],
            editableRowIndex: null
        }
    },
    components: {
        FylkeVassdrag

    },
    mounted() {
        let self = this
        api.fetchData({ url: 'MottakFiskeskjellFisketider/GetFisketider' }).then(res => {
            self.fisketider = res.data
        })
    },
    computed: {
        filteredList() {
            if (this.fylkeVassdrag.vassdragId > 0) {
                return this.fisketider.filter(fisketid => fisketid.vassdragId === this.fylkeVassdrag.vassdragId)
            }
            else if (this.fylkeVassdrag.fylkeId > 0) {
                var nyttFylkenavn = this.finnNyttFylkenavn(this.fylkeVassdrag.fylkeId)
                return this.fisketider.filter(fisketid => fisketid.fylke === nyttFylkenavn)
            } else {
                return this.fisketider
            }
        }
    },
    methods: {
        editComment(index) {
            this.editableRowIndex = index
        },
        saveComment(index) {
            var fisketidToSave = this.filteredList[index]
            if (fisketidToSave.comment != null) {
                api.saveData({ url: "MottakFiskeskjellFisketider/SaveComment", data: fisketidToSave})
            }
            this.editableRowIndex = null;
        },
        isEditable(rowIndex) {
            return rowIndex == this.editableRowIndex;
        },
        finnNyttFylkenavn(gammelFylkeId) {
            if (gammelFylkeId === 19 || gammelFylkeId === 20) {
                return 'Troms og Finnmark'
            } else if (gammelFylkeId === 12 || gammelFylkeId === 14) {
                return "Vestland"
            } else if ( gammelFylkeId === 6 || gammelFylkeId === 1 ){
                return "Viken"
            }
            else if ( gammelFylkeId === 11 ){
                return "Rogaland"
            }   
            else if (gammelFylkeId === 7 || gammelFylkeId === 8) {
                return "Vestfold og Telemark";
            }
            else if (gammelFylkeId === 17 || gammelFylkeId === 16) {
                return "Trøndelag";
            }
            else if (gammelFylkeId === 9 || gammelFylkeId === 10) {
                return "Agder";
            }
            else if (gammelFylkeId === 15 ) {
                return "Møre og Romsdal";
            }
            else if (gammelFylkeId === 18 ) {
                return "Nordland";
            }
            else if (gammelFylkeId === 2 ) {
                return "Oslo";
            }
            else {
                return ''
            }
        }
        
    }
};
</script>
  