<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="onSubmit" enctype="multipart/form-data">
                <div class="container box mt-5">
                    <h1 class="title">Mottak av skjellprøver</h1>
                    
                    <div class="columns">
                        <div class="column is-9">
                            <FylkeVassdrag :model="mottatteSkjellprover.fylkeVassdrag" :validationRules="{ required: true }"
                                :angiProvested=true>
                            </FylkeVassdrag>
                        </div>

                    </div>
                    <div class="columns">
                        <div class="column is-2">
                            <label class="label">Dato mottatt *</label>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <flat-pickr v-model="mottatteSkjellprover.dateReceived"></flat-pickr>
                                <p class="help is-danger">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        
                        <div class="column is-2">
                            <div class="control">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <field-select label-text="Type fiske *" v-model="mottatteSkjellprover.fishingTypeId"
                                        v-bind:options="fishingTypes" :error="errors[0]" />
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        
                        <div class="column is-2">
                            <div class="control">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <field-input label-text="Fangstår *" v-model="mottatteSkjellprover.sampleYear"
                                        :error="errors[0]"  :disable="!waitForUserInput" />
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="column is-2">
                            <div class="control">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <field-select label-text="Prøvetype *" v-model="mottatteSkjellprover.sampleId"
                                        v-bind:options="sampleTypes" :error="errors[0]" :disable="!waitForUserInput" />
                                </ValidationProvider>
                            </div>
                        </div>

                                               <div class="column is-2">
                            <ValidationProvider rules="">
                                <label class="label">Prøver fra </label>
                                <flat-pickr v-model="mottatteSkjellprover.fishingPeriodStart" :disabled="!waitForUserInput"></flat-pickr>
                            </ValidationProvider>
                        </div>
                        <div class="column is-2">
                            <ValidationProvider rules="">
                                <label class="label">Prøver til</label>
                                <flat-pickr v-model="mottatteSkjellprover.fishingPeriodEnd" :disabled="!waitForUserInput"></flat-pickr>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-9">
                            <AntallArter :model="mottatteSkjellprover.arter" :isDisabled="!waitForUserInput"></AntallArter>
                        </div>
                        <div class="column is-3">
                            <div class="notification is-info is-light">
                                <p class="is-underlined">Fisketider laks (sportsfiske):</p>
                                <p>{{ getFiskeperiodeDato }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-3">
                            <field-input label-text="Løpenr" v-model="mottatteSkjellprover.lopenr" name="Lopenummer" :disable="!waitForUserInput">
                            </field-input>
                            <p class="help"> Siste løpenr: <strong>{{ sisteLopenr }}</strong></p>
                        </div>
                        <div class="column is-6">
                            <field-input label-text="Anmerkning" v-model="mottatteSkjellprover.note" name="Kommentar" :disable="!waitForUserInput">
                            </field-input>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-3">
                            <div class="control">
                                <ValidationProvider rules="">
                                    <field-select label-text="Prosjektnummer" v-bind:options="projects"
                                        placeholder="Prosjektnummer" v-model="mottatteSkjellprover.projectId" :disable="!waitForUserInput" />
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="column is-2" v-if="mottatteSkjellprover.id == null">
                            <ValidationProvider rules="">
                                <field-input label-text="Mottat av" v-model="getUserName" :disable="true"></field-input>
                            </ValidationProvider>
                        </div>
                    </div>

                    <Person :model="mottatteSkjellprover.sender" :lregnr="mottatteSkjellprover.fylkeVassdrag.vassdragId"
                        :fisketypeId="mottatteSkjellprover.fishingTypeId" ref="innsendere" :isDisabled="!waitForUserInput"></Person>



                    <div id="file-js-example" class="file has-name" style="margin-top: 50px; margin-bottom: 30px;">
                        <label class="file-label">
                            <input class="file-input" multiple type="file" name="files" @change="onFileChange" >
                            <span class="file-cta">
                                <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label">
                                    Last opp følgebrev...
                                </span>
                            </span>
                        </label>
                    </div>

                    <div v-if="files.length > 0">
                        <div><strong>Følgebrev</strong> <a class="ml-2" @click="removeFiles">Fjern</a></div>
                        <ul>
                            <li v-for="(file, index) in files" v-bind:key="index"> {{ files[index].name }}</li>
                        </ul>
                    </div>

                    <div v-if="mottatteSkjellprover.existingFiles && mottatteSkjellprover.existingFiles.length > 0">
                        <label class="label"> Lagrede følgebrev </label>
                        <ul v-for="existingFile in mottatteSkjellprover.existingFiles" v-bind:key="existingFile.id">
                            <li @click="downloadFile(existingFile)">
                                <a download>
                                    <span class="icon is-small"><i class="fa fa-download" aria-hidden="true"></i></span>
                                    <span> {{ existingFile.fileName }} </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="columns mt-4">
                        <div class="column">
                            <button type="submit" class="button is-info" :disabled="!waitForUserInput" >
                                <span class="icon is-small">
                                    <i class="fa fa-save"></i>
                                </span>
                                <span>Lagre</span>
                            </button>
                            <button type="button" class="button is-default ml-2"
                                v-on:click="clearForm">
                                <span class="icon is-small">
                                    <i class="fa fa-times"></i>
                                </span>
                                <span>Nullstill skjema</span>
                            </button>

                            <button type="button" class="button is-danger is-outlined ml-2"
                                v-if='mottatteSkjellprover.id > 0' v-on:click="deleteMottakSample">
                                <span class="icon is-small">
                                    <i class="fa fa-trash"></i>
                                </span>
                                <span>Slett</span>
                            </button>

                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>
<script>
import AntallArter from '../grouped/AntallArter.vue';
import FylkeVassdrag from '../grouped/FylkeVassdrag.vue';
import FieldInput from '../globals/field-input.vue';
import SampleTypeEnum from "../../enums/SampleTypeEnum.js";
import FishingTypeEnum from "../../enums/FishingTypeEnum.js"
import Person from '../grouped/Person.vue';
import api from "@/api/basicApi.js";
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';

export default {
    components: { FylkeVassdrag, AntallArter, FieldInput, Person, "confirm-dialogue": ConfirmDialogue },
    data: function () {
        return {
            sampleTypes: SampleTypeEnum,
            fishingTypes: FishingTypeEnum,
            mottatteSkjellprover: this.initMottakSkjellprover(),
            user: JSON.parse(localStorage.getItem("user")),
            files: [],
            tabselect: 'register',
            sisteLopenr: '',
            fiskeperiode: '',
            projects: []
        }
    },


    mounted() {
        if (this.$route.params.id > 0) {
            this.getFiskeskjellSample(this.$route.params.id)
        }
        let self = this
        api.fetchData({ url: 'MottakFiskeskjell/GetProjects' }).then(res => {
            self.projects = res.data
        })
    },
    computed: {
        getUserName() {
            if (this.user) {
                return this.user.firstName + ' ' + this.user.lastName
            } else {
                return "Ikke innlogget"
            }
        },
        getFiskeperiodeDato() {
            if (this.fiskeperiode != '') {
                return this.fiskeperiode.laksFisketiderSportsfiske;
            } else {
                return "";
            }
        },
        getFiskeperiodeComment() {
            if (this.fiskeperiode != '') {
                return this.fiskeperiode.comment;
            } else {
                return ""
            }
        },
        waitForUserInput() {
            return this.mottatteSkjellprover.fishingTypeId > 0 && this.mottatteSkjellprover.dateReceived != null && this.mottatteSkjellprover.fylkeVassdrag && this.mottatteSkjellprover.fylkeVassdrag.vassdragId != null
        }


    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            let self = this;
            self.$refs.form.validate().then(success => {
                if (!success) {
                    self.
                        return;
                } else {
                    const formData = new FormData();
                    formData.append("id", self.mottatteSkjellprover.id == null ? 0 : self.mottatteSkjellprover.id)
                    formData.append("dateReceived", self.mottatteSkjellprover.dateReceived)
                    formData.append("sampleYear", self.mottatteSkjellprover.sampleYear)
                    formData.append("sampleId", self.mottatteSkjellprover.sampleId)
                    formData.append("fishingTypeId", self.mottatteSkjellprover.fishingTypeId)
                    formData.append("lopenr", self.mottatteSkjellprover.lopenr !== null ? self.mottatteSkjellprover.lopenr : '');
                    formData.append("note", self.mottatteSkjellprover.note !== null ? self.mottatteSkjellprover.note : '');

                    if (self.mottatteSkjellprover.projectId != null) {
                        formData.append("projectId", self.mottatteSkjellprover.projectId);
                    }

                    formData.append("fishingPeriodStart", self.mottatteSkjellprover.fishingPeriodStart !== null ? self.mottatteSkjellprover.fishingPeriodStart : '');
                    formData.append("fishingPeriodEnd", self.mottatteSkjellprover.fishingPeriodEnd !== null ? self.mottatteSkjellprover.fishingPeriodEnd : '');


                    Object.entries(self.mottatteSkjellprover.fylkeVassdrag).forEach(([key, value]) => {
                        formData.append(`fylkeVassdrag[${key}]`, value != null ? value : '');
                    });

                    Object.entries(self.mottatteSkjellprover.arter).forEach(([key, value]) => {
                        formData.append(`arter[${key}]`, value != null ? value : '');
                    });
                    Object.entries(self.mottatteSkjellprover.sender).forEach(([key, value]) => {
                        formData.append(`sender[${key}]`, value != null ? value : '');
                    });

                    formData.append("createdBy", self.user.id);

                    //let filesToUpload = [];
                    // for (let i = 0; i < self.files.length; i++) {
                    //     filesToUpload.push(self.files[i]);
                    // }
                    //formData.append('files', filesToUpload);
                    //  self.files.forEach((file, index) => {
                    //      formData.append(`filesToUpload[${index}]`, file);
                    //  });

                    formData.append("filesToUpload", self.files[0])

                    api.saveData({ url: "MottakFiskeskjell/Save", data: formData, headers: { "Content-Type": "multipart/form-data" } }).then(function () {
                        self.clearForm()

                    });

                }
            })
        },
        initMottakSkjellprover() {
            return {
                id: 0,
                sampleId: null,
                fishingTypeId: null,
                dateReceived: null,
                sampleYear: null,
                createdBy: null,
                note: '',
                lopenr: '',
                projectId: null,
                projectName: '',
                fylkeVassdrag: {
                    fylkeId: '',
                    vassdragId: null,
                    funnsted: ''
                },
                arter: {
                    laks: 0,
                    aure: 0,
                    røye: 0,
                    pukkellaks: 0,
                    regnbueørret: 0,
                    annet: 0,
                    annetTekst: ''
                },
                sender: {
                    id: 0,
                    innsender: "",
                    kontonr: "",
                    telefon: "",
                    epost: "",
                    kontaktperson: "",
                    adresse: '',
                    lregnr: "",
                    typeFiskeId: "",
                    typeFiskeTekst: "",
                    porto: "",
                    utbetalesTil: ""
                },
                fishingPeriodStart: '',
                fishingPeriodEnd: ''
            }
        },
        clearForm() {
            this.mottatteSkjellprover = this.initMottakSkjellprover()
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
            this.removeFiles()
            this.$refs.innsendere.getInnsendere();
            this.$refs.innsendere.cancelNewUser();
            this.sisteLopenr = '',
            this.$router.push({ name: "registrer" });

        },
        onFileChange(event) {
            this.files = Array.from(event.target.files);
        },
        removeFiles() {
            this.files = []
            this.$nextTick(() => {
                const fileInput = document.querySelector('input[name="files"]');
                fileInput.value = '';
            });
        },
        downloadFile(fileObject) {
            api.downloadFile({ url: "MottakFiskeskjell/DownloadFile/" + fileObject.id }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileObject.fileName);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.parentNode.removeChild(link);
            })
        },
        getFiskeskjellSample(id) {
            let self = this
            api.fetchData({ url: "MottakFiskeskjell/GetFiskeskjellSample/" + id }).then(function (res) {
                self.mottatteSkjellprover = res.data;
            });
        },
        cancelEdit(e) {
            e.preventDefault();
            this.$router.back()

        },
        getSisteLopenr() {
            let self = this
            if (this.mottatteSkjellprover.fylkeVassdrag.vassdragId != null && this.mottatteSkjellprover.fishingTypeId != null && this.mottatteSkjellprover.dateReceived != null) {
                var year = new Date(this.mottatteSkjellprover.dateReceived).getFullYear()
                api.fetchData({ url: "MottakFiskeskjell/GetSisteLopenr?lreg=" + this.mottatteSkjellprover.fylkeVassdrag.vassdragId + "&type=" + this.mottatteSkjellprover.fishingTypeId + "&year=" + year + "&funnsted=" + this.mottatteSkjellprover.fylkeVassdrag.funnsted })
                    .then(function (res) {
                        self.sisteLopenr = res.data;
                        return res.data
                    });
            }
            return ""
        },
        getFiskeperiode() {
            let self = this
            if (this.mottatteSkjellprover.fylkeVassdrag.vassdragId != null) {

                api.fetchData({ url: "MottakFiskeskjellFisketider/GetFisketiderForLreg?lreg=" + this.mottatteSkjellprover.fylkeVassdrag.vassdragId })
                    .then(function (res) {
                        self.fiskeperiode = res.data;
                    });

            } else {
                self.fiskeperiode = "";
            }

        },
        deleteMottakSample(e) {
            e.preventDefault();
            let self = this
            this.$refs.confirmDialogue.show({
                title: 'Slett mottak av prøver',
                message: 'Er du sikker på at du vil slette mottak av prøver?',
                okButton: 'Slett',
            }).then((result) => {
                if (result) {
                    api.deleteData({ url: "MottakFiskeskjell/Delete/" + self.mottatteSkjellprover.id }).then(function () {
                        self.clearForm()
                    })
                }
            })
        }
    },
    watch: {
        '$route'(to) {
            if (to.name === 'registrer') {
                if (this.$route.params.id > 0) {
                    this.getFiskeskjellSample(this.$route.params.id)
                }
            }

        },
        'mottatteSkjellprover.fylkeVassdrag.vassdragId'(newVal) {
            if (newVal != null) {
                this.getSisteLopenr()
                this.getFiskeperiode()
            }
        },
        'mottatteSkjellprover.fishingTypeId'(newVal) {
            if (newVal != null) {
                this.getSisteLopenr()
            }
        },
        'mottatteSkjellprover.dateReceived'(newVal) {
            if (newVal != null) {
                this.getSisteLopenr()
            }
        }
    }

}

</script>