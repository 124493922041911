var api_url = "";

switch (process.env.NODE_ENV) {
  case "development":
    api_url = "https://localhost:44378/api";
    //api_url = "https://biobase.nina.no/api/api";
    break;
  case "production":
    // api_url = "https://ninweb17.nina.no/Biobase/api/api";
    api_url = "https://biobase.nina.no/api/api";
    break;
}

export const PROVEBASE_CONFIG = {
  API_URL: api_url
};
