<template>
  <popup-modal ref="checkoutDialogue">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Sjekk ut prøver fra mottaksfryser</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <ValidationObserver ref="checkout">
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <field-input labelText='Sjekket ut av *' placeholder="Ditt navn" v-model="checkedOutBy"
                  :error="errors[0]"></field-input>
              </ValidationProvider>
            </div>
          </div>

        </section>
      </ValidationObserver>
        <footer class="modal-card-foot">
          <button class="button is-info" @click="checkout">Sjekk ut </button>
          <button class="button" @click="close()">
            Avbryt
          </button>
        </footer>
      </div>
    </div>
  </popup-modal>
</template>

<script>
import FieldInput from '../globals/field-input.vue';

import PopupModal from './PopupModal.vue'
export default {
  name: "CheckoutMottakSaplesDialogue",
  data: function () {
    return {
      checkedOutBy: '',
      resolvePromise: undefined,
      rejectPromise: undefined
    };
  },
  components: { PopupModal, FieldInput },

  props: {},
  methods: {
    show() {
      this.$refs.checkoutDialogue.open();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    checkout() {
      this.$refs.checkout.validate().then(success => {
        if (!success) {
          return;
        }
        this.$emit('checkedOutBy', this.checkedOutBy)
        this.$refs.checkoutDialogue.close()
        this.resolvePromise(true)
      })
      

    },
    close() {
      this.$refs.checkoutDialogue.close()
      this.resolvePromise(false)
    }
  }
};
</script>

<style scoped></style>
