<template>
  <div class="hello">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Koble individ</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <label class="label">Søk i eksterne registre</label>

              <div class="field">
                <div class="control">
                  <label class="radio">
                    <input type="radio" name="foobar" checked />
                    Hjorteviltregiseret
                  </label>
                </div>
              </div>
              <div class="field is-grouped">
                <p class="control is-expanded">
                  <input class="input" type="text" v-model="externalId" placeholder="Strekkode" />
                </p>
                <p class="control">
                  <a class="button is-info" @click="searchById()">
                    Søk
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="notification is-warning" v-show="idInUse">
            <p>
              Det er allerede registrert et individ med denne strekkoden. Ta
              kontakt med systemansvarlig hvis du ønsker å knytte individet til
              denne strekkoden.
            </p>
          </div>
          <div class="my-6" v-show="showSearchResult">
            <search-result :animal="animal" :isFound="isFound" :isEdit="false"></search-result>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="link()" :disabled="isFound == false">
            <span class="icon is-small">
              <i class="fa fa-link"></i>
            </span>
            <span>Koble</span>
          </button>
          <button class="button" @click="close()">
            Avbryt
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import SearchResult from "@/components/IndividualSearchResultHVR.vue";
import api from "@/api/basicApi.js";
export default {
  name: "LinkIndividuals",
  data: function () {
    return {
      showLinkModal: false,
      isFound: false,
      externalId: "",
      animal: {},
      idInUse: false,
      showSearchResult: false
    };
  },
  components: {
    "search-result": SearchResult
  },
  computed: {},
  methods: {
    searchById() {
      let self = this;
      self.idInUse = false;
      api.fetchData({ url: "Individ/ExternalIdExists/" + self.externalId }).then(res => {
        if (res.data) {
          self.idInUse = true;
        } else {
          api
            .fetchData({
              url: "Individ/SearchByExternalId/" + self.externalId
            })
            .then(res => {
              if (res.data === null) {
                self.isFound = false;
              } else {
                self.animal = res.data;
                self.isFound = true;
              }
            });
          self.showSearchResult = true;
        }
      });
    },
    close() {
      this.$emit("showModal", false);
    },
    link() {
      this.$emit("link", this.animal.externalId);
      this.$emit("showModal", false);
    }
  }
};
</script>

<style scoped>

</style>
