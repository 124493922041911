<template>
  <div class="field">
    <label class="label">{{ labelText }}</label>
    <div class="control">
      <input
        class="input"
        :type="inputType"
        :value="value"
        @input="input"
        v-bind="$attrs"
        :disabled="disable"
        :placeholder="placeholder"
        :min="isIntegerType ? 0 : null"
      />
      <span class="help is-danger">{{ error }}</span>
    </div>
  </div>
</template>

<script>
// Eg. <field-input label-text="Eartag" v-model="tag.eartag" name="Eartag" v-validate="'required'" :error="errors.first('Eartag')" />
export default {
  inheritAttrs: false,
  props: {
    labelText: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number],
      required: false
    },
    disable: {
      type: Boolean,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    }
  },
  computed: {
    isIntegerType() {
      return this.inputType === 'number';
    }
  },
  methods: {
    input(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>
<style></style>
