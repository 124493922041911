<template>
    <ValidationObserver ref="form" >
        <form @submit.prevent="submitForm">
        <div class="field">
            <field-input label-text="Antall prøver utbetalt" inputType="number" v-model="model.antProverUtbetalt" name="Antall prøver utbetalt" />    
        </div>
        <div class="field">
                <field-input label-text="Porto" inputType="number" v-model="model.porto" name="Porto" />
        </div>

        <div class="field">
                <field-input label-text="Beløp" v-model="model.belop" inputType="number" name="Beløp" />
        </div>

        <div class="field">
            <field-input label-text="Navn" v-model="model.navn" name="Navn" />
        </div>

        <div class="field">
            <field-input label-text="Adresse" v-model="model.adresse" name="Adresse" />
        </div>

        <div class="field">
            <field-input label-text="Personnummer" v-model="model.personnummer" name="Personnummer" />
        </div>

        <div class="field">
            <field-input label-text="Kontonummer" v-model="model.kontonummer" name="Kontonummer" />
        </div>

        <div class="field">
            <field-input label-text="Utbetalt for" v-model="model.utbetaltFor" name="Utbetalt for" />
        </div>
   
        <button class="button is-info mr-1" type="submit"><span class="icon is-small">
              <i class="fa fa-save"></i>
            </span>
            <span>Lagre</span> 
          </button>

          
    </form>
       
    </ValidationObserver>

</template>

<script>
export default {
    name: "RegistrerBetaling",
    data: function () {
        return {
            // betaling: {
            //     antProverUtbetalt: null,
            //     porto: null,
            //     belop: null,
            //     navn: null,
            //     adresse: null,
            //     utbetaltFor: null,
            //     kontonummer: null,
            //     personnummer: null
            // }
        }
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    mounted() { },
    methods: {
        submitForm() {
            this.$emit('formSubmitted', this.model );
        }
       
    }
}


</script>
