<template>
    <div class="columns">
        <div class="column">
            <field-input label-text="Laks" v-model="model.laks" name="Laks" inputType="number" :disable="isDisabled">
            </field-input>
        </div>
        <div class="column">
            <field-input label-text="Aure" v-model="model.aure" name="Aure" inputType="number" :disable="isDisabled">
            </field-input>
        </div>
        <div class="column">
            <field-input label-text="Røye" v-model="model.røye" name="Røye" inputType="number" :disable="isDisabled">
            </field-input>
        </div>
        <div class="column">
            <field-input label-text="Pukkellaks" v-model="model.pukkellaks" name="Pukkellaks" inputType="number" :disable="isDisabled"></field-input>
        </div>
        <div class="column">
            <field-input label-text="Regnbueø." v-model="model.regnbueørret" name="Regnbueørret" inputType="number" :disable="isDisabled"></field-input>
        </div>
        <div class="column">
            <field-input label-text="Annet" v-model="model.annet" name="Annet" inputType="number" :disable="isDisabled"></field-input>
        </div>
        <div class="column is-3 ml-1">
            <field-input label-text="Annet - beskrivelse" v-model="model.annetTekst" name="Annet" :disable="isDisabled"></field-input>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            required: true,
            default: true
        }
    },
}
</script>