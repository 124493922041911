<template>
  <div>
    <section class="section">
      <div class="container">
        <p>{{ verifyMessage }}</p>
        <p class="mt-4">
          <router-link to="/login">Til innlogging</router-link>
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/basicApi.js";
//import { EventBus } from "@/event-bus.js";
export default {
  data() {
    return {
      message: ""
    };
  },

  mounted() {
    let self = this;
    let token = this.$route.query.verifyToken;
    let verifyReq = { token: token };
    api
      .postData({ url: "Accounts/verify-email", data: verifyReq })
      .then(res => {
        console.log(res);
        self.message =
          "Din e-post er verifisert og du kan nå logge inn. Systemeier må gi deg rettigheter før du får tilgang til systemet.";
      })
      .catch(error => {
        console.log(error);
        self.message =
          "Verifisering av din e-post feilet. Du kan også verifisere din e-post ved å bruke glemt passord-funksjonen.";
      });
  },
  computed: {
    verifyMessage() {
      return this.message;
    }
  }
};
</script>
