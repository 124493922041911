<template>
  <popup-modal ref="formModal">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <div class="modal-card-body">
          <slot></slot>
        </div>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="close()">
            <span class="icon is-small">
              <i class="fa fa-times"></i>
            </span>
            <span>Avbryt</span>
          </button>
        </footer>
      </div>
    </div>
  </popup-modal>
</template>

<script>

import api from "@/api/basicApi.js";
import PopupModal from './PopupModal.vue'
export default {
  name: "FormModal",
  data: function () {
    return {
    };
  },
  components: { PopupModal },

  props: {
    title: {
      type: String,
      required: false
    }
  },
  mounted() {
    let self = this
    this.hasValidationErrors = false;
    api.fetchData({ url: "Mottak/GetDropdownsForMottakBulkEdit" }).then(res => {
      self.dropdowns = res.data;
    })

  },
  methods: {
    show() {
      this.$refs.formModal.open();
    },
    bulkEdit() {
      let hasSelectedFieldsValues = this.validateEditValues()
      if (hasSelectedFieldsValues) {
        
        this.$emit('valuesToUpdate', this.unit)
        this.$refs.bulkEditDialog.close()
        this.initBulkEdit()
      } else {
        this.hasValidationErrors = true
      }

    },
    close() {
      this.$refs.formModal.close()
      this.initBulkEdit()
    },

    validateEditValues() {
      if (this.editSpecies && this.unit.speciesId == null) return false
      if (this.editProvetype && this.unit.sampleMaterialId == null) return false
      if (this.editProject && this.unit.projectId == null) return false
      if (this.editLeader && this.unit.projectLeader == null) return false
      if (this.editRegisteredBy && this.unit.createdBy == null) return false
      if (this.checkinDate && this.unit.checkinDate == null) return false
      if (this.checkoutDate && this.unit.checkoutDate == null) return false
      if (this.checkedOutDate && this.unit.checkedOutDate == null) return false
      if (this.editStorageLocation && this.unit.storageLocationId == null) return false
      if (this.editStorageLocationDetail && this.unit.storageLocationDetailId == null) return false
      if (this.editComment && this.unit.comment == null) return false
      return true
    },

    initBulkEdit() {
      this.unit = {},
        this.editSpecies = false,
        this.editProvetype = false,
        this.editProject = false,
        this.editLeader = false,
        this.editRegisteredBy = false,
        this.checkinDate = false,
        this.checkoutDate = false,
        this.checkedOutBy = false,
        this.editStorageLocation = false,
        this.editStorageLocationDetail = false,
        this.editComment = false,
        this.hasValidationErrors = false,
        this.undoCheckout = false
    },
    getStorageLocationDetails() {
      let self = this
      api.fetchData({ url: "Mottak/GetStorageDetailsForLocation/" + this.unit.storageLocationId }).then(res => {
        self.storageLocationDetails = res.data
      })
    },
  },
  watch: {
    'unit.storageLocationId'(newVal) {
      if (newVal != null) {
        this.getStorageLocationDetails()
      }
    }
  }
};
</script>

<style scoped>
.field-label {
  flex-grow: 2;
  text-align: left;
}
</style>
