<template>
  <div>
    <section class="section">
      <div class="box">
        <div class="container" v-show="success == false">
          <h1 class="title">Registrer bruker</h1>
          <div class="columns">
            <div class="column is-4">
              <span> {{ errors[0] }}</span>
              <ValidationObserver ref="form">
                <form @submit.prevent="onSubmit">
                  <div class="form-group">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <field-input
                        label-text="Fornavn"
                        placeholder="Fornavn"
                        v-model="user.firstName"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <field-input
                        label-text="Etternavn"
                        placeholder="Etternavn"
                        v-model="user.lastName"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      :rules="{ required: true, email: true }"
                      v-slot="{ errors }"
                    >
                      <field-input
                        label-text="E-post"
                        placeholder="E-post"
                        inputType="email"
                        v-model="user.email"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      rules="required|min:6"
                      v-slot="{ errors }"
                      vid="password"
                    >
                      <field-input
                        inputType="password"
                        label-text="Passord"
                        placeholder="Passord"
                        v-model="user.password"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                      data-vv-as="password"
                    >
                      <field-input
                        inputType="password"
                        label-text="Bekreft passord"
                        placeholder="Gjenta passord"
                        v-model="user.confirmPassword"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="form-group mt-4">
                    <div class="columns">
                      <div class="column">
                        <button type="submit" class="button is-info">
                          <span class="icon is-small">
                            <i class="fa fa-save"></i>
                          </span>
                          <span>Registrer</span>
                        </button>

                        <button
                          type="button"
                          class="button is-default ml-2"
                          v-on:click="cancel()"
                        >
                          <span class="icon is-small">
                            <i class="fa fa-times"></i>
                          </span>
                          <span>Avbryt</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <div v-show="success">
          <p>
            Takk for at du opprettet en bruker. Du har fått tilsendt en e-post.
            Følg instruksjonene i e-posten for å verifisere din bruker.
          </p>
          <p class="mt-4">
            <router-link to="/login">Til innlogging</router-link>
            &nbsp;
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/api/basicApi.js";
export default {
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
      },
      submitted: false,
      status: {},
      success: false,
      errors: []
    };
  },

  methods: {
    onSubmit() {
      let self = this;
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        api
          .postData({ url: "Accounts/register", data: this.user })
          .then(res => {
            console.log(res);
            self.success = true;
          });
      });
    },
    cancel() {
      this.$router.push({ path: "/login" });
    }
  }
};
</script>
