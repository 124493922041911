<template>
    <div>
        <div class="columns">
            <div class="column is-4">
                <label class="label">Innsender</label>
                <div class="control">
                    <v-select ref="innsenderSelect" class="style-chooser" placeholder="Velg person eller skriv for å søke"
                        v-bind:options="users" @input="selectUser" :disabled="createNewUser || isDisabled"
                        :getOptionLabel="user => user.innsender">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Ingen resultat for <em>{{ search }}</em>.
                            </template>
                            <em v-else style="opacity: 0.5">Ingen personer funnet</em>
                        </template>
                    </v-select>
                </div>

            </div>
            <div class="column is-3">
                <label class="label"></label>
                <div class="control" v-show="!createNewUser">
                    <button class="button is-info is-outlined mt-5" @click="createUser" :disabled="isDisabled">
                        <i class="fas fa-user-plus"></i> <span class="ml-2"> Ny innsender</span></button>
                </div>
                <div v-show="createNewUser">
                    <button class="button is-info is-outlined mt-5" @click="cancelNewUser">
                        <i class="fas fa-user-plus"></i> <span class="ml-2"> Avbryt</span></button>
                </div>
            </div>
            <div class="column is-2">
                <label class="label"></label>
                <div v-show="model.id > 0">
                    <div class="control" v-show="!enableFields">
                        <button class="button is-info is-outlined mt-5" @click="editUser">
                            <i class="fas fa-user-plus"></i> <span class="ml-2"> Rediger</span></button>
                    </div>
                    <div class="control" v-show="enableFields">
                        <button class="button is-info is-outlined mt-5" @click="cancelEditUser">
                            <i class="fas fa-user-plus"></i> <span class="ml-2"> Avbryt</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="row">
                <div class="columns">
                    <div class="column is-4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <field-input v-model="model.innsender" :disable="!enableFields"
                                labelText="Navn innsender *"></field-input>
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>

                    <div class="column is-2">
                        <field-input v-model="model.epost" :disable="!enableFields" labelText="E-post"></field-input>
                    </div>

                    <div class="column is-2">
                        <field-input v-model="model.telefon" :disable="!enableFields" labelText="Telefon"></field-input>
                    </div>
                    <div class="column is-4">
                        <field-input v-model="model.adresse" :disable="!enableFields" labelText="Adresse"></field-input>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="columns">

                    <div class="column is-4">
                        <field-input v-model="model.utbetalesTil" :disable="!enableFields"
                            labelText="Utbetales til"></field-input>
                    </div>
                    <div class="column is-4">
                        <field-input v-model="model.kontaktperson" :disable="!enableFields"
                            labelText="Kontaktperson"></field-input>
                    </div>
                    <div class="column is-2">
                        <field-input v-model="model.kontonr" :disable="!enableFields" labelText="Kontonr"></field-input>
                    </div>
                    <div class="column is-2">
                        <field-input v-model="model.porto" :disable="!enableFields" labelText="Porto"></field-input>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="columns">
                    <div class="column is-2">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                        <field-select v-model="model.typeFiskeId" :options="fishingTypes" :disable="!enableFields"
                            labelText="Type fiske"></field-select>
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="column is-2">
                        <field-input v-model="model.typeFiskeTekst" v-if="model.typeFiskeId === 4" :disable="!enableFields"
                            labelText="Beskrivelse"></field-input>
                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
import fieldInput from '../globals/field-input.vue'
import api from "@/api/basicApi.js";
import fishingTypeEnum from "@/enums/FishingTypeEnum";

export default {
    components: { fieldInput },
    props: {
        model: {
            type: Object,
            required: true,
        },
        lregnr: null,
        fisketypeId: null,
        isDisabled: {
            type: Boolean,
            required: true,
            default: true
        }
    },
    data: function () {
        return {
            users: [],
            createNewUser: false,
            enableFields: false,
            originalUser: {},
            fishingTypes: fishingTypeEnum
        }
    },

    mounted() {
    },
    methods: {

        getInnsendere() {
            var self = this;
            if (this.lregnr != null && this.fisketypeId != null) {
                api.fetchData({ url: "MottakFiskeskjell/GetInnsendere?lregnr=" + this.lregnr + "&fisketype=" + this.fisketypeId }).then(res => {
                self.users = res.data;
            });
            }
            
        },
        setUser(user) {
            this.model.id = user.id
            this.model.innsender = user.innsender
            this.model.kontonr = user.kontonr
            this.model.telefon = user.telefon
            this.model.epost = user.epost
            this.model.kontaktperson = user.kontaktperson
            this.model.lregnr = user.lregnr
            this.model.typeFiskeId = user.typeFiskeId
            this.model.typeFiskeTekst = user.typeFiskeTekst
            this.model.porto = user.porto
            this.model.adresse = user.adresse
            this.model.utbetalesTil = user.utbetalesTil
        },
        clearUser() {
            this.model.id = 0,
                this.model.innsender = ""
            this.model.kontonr = ""
            this.model.telefon = ""
            this.model.epost = ""
            this.model.kontaktperson = ""
            this.model.lregnr = ""
            this.model.typeFiskeId = ""
            this.model.typeFiskeTekst = ""
            this.model.porto = ""
            this.model.utbetalesTil = ""
            this.model.adresse = ""
        },

        selectUser(user) {
            if (user) {
                this.setUser(user)
            } else {
                this.clearUser()
            }
        },
        createUser(e) {
            e.preventDefault()
            this.clearUser()
            this.$refs.innsenderSelect.clearSelection();
            this.enableFields = true
            this.createNewUser = true
            this.model.lregnr = this.lregnr
        },
        editUser(e) {
            e.preventDefault()
            this.originalUser = { ...this.model }
            this.enableFields = true
        },
        cancelEditUser(e) {
            e.preventDefault()
            this.setUser(this.originalUser)
            this.enableFields = false
        },
        cancelNewUser() {
            this.clearUser()
            this.createNewUser = false
            this.enableFields = false
        },

        cancelSaveUser() {
            this.createNewUser = false
        },
        saveUser() {
            this.createNewUser = false
        }

    },
    watch: {
        'lregnr'(nyLreg) {
            if (nyLreg != null) {
                this.getInnsendere()
            }
        },
        'fisketypeId'(nyFisketypeId){
            if(nyFisketypeId != null) {
                this.getInnsendere()
            }
        }
    }
}
</script>